import { Spin, Drawer, Button } from "antd"
import { useState, Fragment } from "react"

import Item from "./Item"
import TopMenu from "./TopMenu"
import { useInfinityFetchQuery } from "../../hooks/useReactQuery"
import { LoadMoreButton } from "../Button"
import DrawerSection from "./DrawerSection"

export default function Dispute({ status, name, path }) {
  const { data, isFetchingNextPage, isLoading, isError, error, fetchNextPage, hasNextPage, refetch } = useInfinityFetchQuery(
    name,
    path || "dispute/admin",
    status.toLocaleLowerCase()
  )

  const [editId, setEditId] = useState(null)

  const showDrawer = (id) => {
    setEditId(id)
  }
  const onClose = () => {
    setEditId(null)
  }

  if (isError) {
    return <p className="px-8 py-10 font-bold">{error.message}</p>
  }

  if (isLoading) {
    return (
      <div className="flex justify-center py-10">
        <Spin />
      </div>
    )
  }

  return (
    <div className="relative">
      <Button type="primary" onClick={refetch} className="mb-5">
        Refetch
      </Button>
      <TopMenu />
      {(!isLoading && !data) || (!isLoading && data.pages.length === 1 && data.pages[0].data.length === 0) ? (
        "No Product found"
      ) : (
        <>
          {data.pages.map((page, i) => (
            <Fragment key={`receiving-card-page-${i}`}>
              {page.data.map((data, index) => (
                <Item key={data._id} data={data} name={name} status={status} showDrawer={showDrawer} setEditId={setEditId} page={i} />
              ))}
            </Fragment>
          ))}
          <LoadMoreButton
            pages={data.pages}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
          />
          <DrawerSection showDrawer={showDrawer} onClose={onClose} editId={editId} queryKey={name} />
        </>
      )}
    </div>
  )
}
