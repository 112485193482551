import { useState } from "react";
import BaseProductForm from "./BaseProductForm";
import BaseProductTable from "./BaseProductTable";

export default function BaseProduct() {
  const [editId, setEditId] = useState(null);
  const handleEdit = (id) => {
    setEditId(id);
  };
  return (
    <>
      <BaseProductForm editId={editId} handleEdit={handleEdit} />
      <BaseProductTable handleEdit={handleEdit} />
    </>
  );
}
