import axios from "axios"

export default async function TryCatch(method, path, data, headers) {
  try {
    const entries = {
      method,
      url: path,
      headers,
    }

    if (method === "DELETE") {
      const res = await axios(entries)
      return [res?.data, null]
    }
    const res = await axios({
      ...entries,
      data,
    })
    if (res?.data?.status === "fail") {
      return [null, res?.data]
    }

    return [res?.data, null]
  } catch (err) {
    return [null, err?.response?.data]
  }
}
