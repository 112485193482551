import React from "react"
import { Button, Tooltip } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
export default function RefetchButton({ refetch, loading, name, className }) {
  return (
    <div className={`absolute right-0 ${className ? className : "top-[51px]"}`}>
      <Tooltip placement="top" title={`Fetch the recent ${name}`}>
        <Button shape="circle" onClick={refetch} icon={<ReloadOutlined />} loading={loading} size="middle" />
      </Tooltip>
    </div>
  )
}
