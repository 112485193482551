import React from "react"

import Wrapper from "../../components/Wrapper"
import Product from "../../components/product/pending"

export default function Active() {
  return (
    <Wrapper>
      <Product status="active" name="active-products" />
    </Wrapper>
  )
}
