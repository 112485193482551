import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Alert, Button, Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import FileUpload from "../FileUpload"

const BaseProductSelectOption = ({ categoryId, setBaseProductId }) => {
  const { data, isLoading, refetch } = UseData("baseProductSelect", `base-product/category/${categoryId}`)
  useEffect(() => {
    refetch()
  }, [categoryId, refetch])

  if (data.length === 0) return null
  return (
    <Form.Item
      name="baseProduct"
      label="Base Product"
      rules={[
        {
          required: true,
          message: "Please select Base Product",
        },
      ]}
    >
      <Select
        placeholder="Select base product"
        allowClear
        showSearch
        loading={isLoading}
        onChange={(e) => setBaseProductId(e)}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {data.map((d) => (
          <Select.Option value={d._id} key={d._id}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const VariationSelectOption = ({ baseProductId }) => {
  const { data, isLoading, refetch } = UseData("variationSelect", `variation/category/${baseProductId}`)

  useEffect(() => {
    refetch()
  }, [baseProductId, refetch])

  if (data.length === 0) return null
  return (
    <Form.List name="variations">
      {(fields, { add, remove }) => (
        <>
          <label className="block mb-4 text-black">Variations</label>
          <div className="flex flex-wrap relative -mx-4">
            {fields.map((field) => (
              <div key={field.key} className="w-1/2 px-4 flex flex-wrap option-outer relative pr-7">
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.variation !== curValues.variation}>
                  {() => (
                    <Form.Item
                      {...field}
                      label="Variation"
                      name={[field.name]}
                      rules={[
                        {
                          required: true,
                          message: "Missing variation",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a Variation"
                        allowClear
                        showSearch
                        loading={isLoading}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {data.map((d) => (
                          <Select.Option value={d._id} key={d._id}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>

                <div className="bg-red-600 w-7 h-7 text-base rounded-full text-white flex flex-col items-center justify-center absolute right-0 top-[34px] px-0 button-dv">
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </div>
              </div>
            ))}
          </div>
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Variation
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default function AddProduct() {
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(true)
  const [success, setSuccess] = useState(false)
  const [categoryID, setCategoryID] = useState(null)
  const [baseProductId, setBaseProductId] = useState(null)
  const [form] = Form.useForm()
  const mutation = useReactQueryMutation("products", "product", file)
  const category = UseData("category", "category")
  const brand = UseData("brand", "brand")
  const setFileHandle = (e) => {
    setFileSet(true)
    setFile(e)
  }
  const submitHandle = async (formValue) => {
    setSuccess(false)
    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return
          if (Object.keys(file).length > 0) {
            setSuccess(true)
          }
          openNotification("Success", `New Product "${formValue.name}" has been created.`)
          form.resetFields()
          setFile({})
        },
      }
    )
    setFileSet(true)
  }
  return (
    <>
      <h1 className="font-bold text-xl">Add Product</h1>

      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Form.Item label="Product Name" name="name" rules={[{ required: true, message: "Please input brand name!" }]}>
          <Input />
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select
                placeholder="Select a category"
                allowClear
                showSearch
                onChange={(e) => setCategoryID(e)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {category.data.map((d) => (
                  <Select.Option value={d._id} key={d._id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="brand"
              label="Brand"
              rules={[
                {
                  required: true,
                  message: "Please select brand",
                },
              ]}
            >
              <Select
                placeholder="Select a brand"
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {brand.data.map((d) => (
                  <Select.Option value={d._id} key={d._id}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {categoryID && (
            <Col span={24}>
              <BaseProductSelectOption categoryId={categoryID} setBaseProductId={setBaseProductId} />
            </Col>
          )}
          {baseProductId && (
            <Col span={24}>
              <VariationSelectOption baseProductId={baseProductId} />
            </Col>
          )}
        </Row>

        <FileUpload setFileHandle={setFileHandle} success={success} />

        {!fileSet && (
          <div className="mb-5">
            <Alert message="Please select the image!" type="error" showIcon />
          </div>
        )}

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}

        <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
          Add and Upload
        </Button>
      </Form>
    </>
  )
}
