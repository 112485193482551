import React, { useState } from "react"
import { Drawer, Image, Select, Tag } from "antd"
import { useQueryClient } from "react-query"
import { getImagePath } from "../../utils/uri"
import { dateFormat } from "../../utils/dateFormat"
import ApproveDeclineButton from "./ActionButton/ApproveDeclineButton"
import EscalateButtons from "./ActionButton/EscalateButtons"
import { renderHTML } from "../../utils/renderHTML"
import { currencyFormat } from "../../utils/formatCurrency"

const Footer = ({ disputeItem, reason, onClose }) => {
  return (
    <div className="p-4 flex justify-center space-x-4">
      {reason.status === "escalated" || reason.isExpired ? (
        <EscalateButtons disputeItem={disputeItem} reason={reason} onClose={onClose} />
      ) : (
        <ApproveDeclineButton disputeItem={disputeItem} reason={reason} onClose={onClose} />
      )}
    </div>
  )
}

export default function DrawerSection({ editId, onClose, queryKey }) {
  const [selectedReasonIndex, setSelectedReasonIndex] = useState(0)

  const queryClient = useQueryClient()

  const dispute = queryClient.getQueryData([queryKey])

  if (!dispute || !editId) return null

  const disputeItem = dispute.pages[editId?.page].data.find((d) => d._id === editId.id)

  if (!disputeItem) return null

  const reason = disputeItem.reasons[selectedReasonIndex]

  const handleOnChange = (id) => {
    setSelectedReasonIndex(disputeItem.reasons.findIndex((r) => r._id === id))
  }

  // if (disputeItem.type === "product") {
  //   return <DrawerSectionIndividual editId={editId} onClose={onClose} disputeItem={disputeItem} />
  // }

  return (
    <Drawer
      title="Dispute Overview"
      footer={<Footer disputeItem={disputeItem} reason={reason} onClose={onClose} />}
      placement="right"
      width={500}
      className="p-0 flex flex-col"
      onClose={onClose}
      open={editId}
    >
      <div className="flex space-x-3 items-center border-0 border-b border-solid border-gray-300 pb-5">
        <div className="w-14 h-14 bg-blue-600 flex items-center justify-center rounded text-white">P</div>
        <div className="capitalize font-medium">
          <div className="text-md">{reason.title}</div>
          <div className="text-xs mt-2">{disputeItem.order.trackingInfo.trackingNumber}</div>
        </div>
      </div>
      <div className="mt-5 font-semibold flex flex-col space-y-1 text-sm">
        <div>Buyer Price: {currencyFormat(disputeItem.type === "product" ? reason.buyerPrice : disputeItem.price)}</div>
        <div>Seller Price: {currencyFormat(disputeItem.type === "product" ? reason.sellerPrice : disputeItem.sellerPrice)}</div>
        <div>Total Qty: {disputeItem.totalQty}</div>
      </div>
      <div className="pt-5">
        <Select
          defaultValue={reason.title}
          style={{ width: "100%" }}
          options={disputeItem.reasons.map((r) => ({ value: r._id, label: r.title }))}
          size="large"
          onSelect={handleOnChange}
        />

        {reason.title === "Other" && <div className="mt-3">{renderHTML(reason.otherMessage)}</div>}
        <div className="mt-2">
          <div className="text-xs mb-1 font-medium px-4">QTY: {reason.quantity}</div>
          <div className="flex">
            {disputeItem.type === "product" && Object.keys(reason.variables).length > 0 && (
              <div className="px-4">
                {Object.keys(reason.variables).map((variableKey, i) => {
                  const value = reason.variables[variableKey]

                  if (!value) return null
                  return (
                    <div key={`${variableKey}-${i}`} className="text-xs font-medium capitalize">
                      {variableKey === "newPrices" ? (
                        <div className="py-1">
                          <div className="py-1">Buy Price: {value.buyer}</div>
                          <div className="py-1">Sell Price: {value.seller}</div>
                        </div>
                      ) : (
                        <div>
                          {variableKey}: <strong className="font-semibold">{value}</strong>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="flex ml-auto space-x-2">
              {reason.images.map((image, i) => (
                <div className="mt-5 border rounded p-2 border-gray-400" key={`image-dispute-${i}`}>
                  <Image src={getImagePath(image)} width={100} height={100} className="object-contain" alt="dispute" />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-auto">
            <div className="text-xs font-medium">Dispute created</div>
            <div className="text-[10px] mt-1">{dateFormat(disputeItem.createdAt)}</div>
          </div>
        </div>
        {reason.responses.map((response, i) => (
          <div className="odd:bg-gray-200 mt-7 -mx-6 p-6 " key={response._id}>
            <Tag color={response.status === "approved" ? "#87d068" : "#EA0000"} className="capitalize">
              {response.status}
            </Tag>
            <div className="bg-white border-1 border-gray-200 p-5 rounded border-solid font-medium mt-2">
              <strong>{response.title}</strong>
              <div className="mt-4">
                {renderHTML(response?.otherMessage)}
                {renderHTML(response?.escalatedMessage)}
                {response?.newTrackingNumber}
              </div>
            </div>

            <div className="flex mt-5">
              <div className="flex space-x-2">
                {response.images.map((image, i) => (
                  <div className="mt-5" key={`image-${i}`}>
                    <Image src={getImagePath(image)} width={100} alt="dispute" />
                  </div>
                ))}
              </div>
              <div className="mt-auto ml-auto">
                <div className="text-xs font-medium capitalize">{response.from}</div>
                <div className="text-[10px] mt-1">{dateFormat(response.createdAt)}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  )
}
