import { useState } from "react";
import CatForms from "./CatForms";
import CatTable from "./CatTable";

export default function Category() {
  const [editId, setEditId] = useState(null);
  const handleEdit = (id) => {
    setEditId(id);
  };
  return (
    <>
      <CatForms editId={editId} handleEdit={handleEdit} />
      <CatTable handleEdit={handleEdit} />
    </>
  );
}
