export const getImagePath = (name) => {
  if (process.env.NODE_ENV === "development") {
    return `http://local.sellb.com:6085/uploads/${name}`
  }
  return `${process.env.REACT_APP_MEDIA_URI}/${name}`
}

let uri = process.env.REACT_APP_API_SITE_URI

if (process.env.NODE_ENV === "development") {
  uri = "http://local.sellb.com:6085/v1"
}

export { uri }
