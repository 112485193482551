import { Alert, Button, Form, Select, Space } from "antd"
import React, { useState } from "react"
import Wrapper from "../../components/Wrapper"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import ApproveBetaUser from "../../components/ApproveBetaUser"

const capitalizeFirstAlphabet = (string) => string.substring(0, 1).toUpperCase() + string.substring(1)

export default function UserRole() {
  const [selectedUser, setSelectedUser] = useState(null)
  const [form] = Form.useForm()

  const { data, isLoading } = UseData("all-users", "user/all-users")
  const mutation = useReactQueryMutation("all-users", "user/role")
  const handleOnChange = (id) => {
    const user = data.find((d) => d._id === id)
    setSelectedUser(user)

    form.resetFields()
    form.setFieldsValue({
      role: user.role,
      user: user._id,
    })
  }
  const submitHandle = async (formValue) => {
    mutation.mutate(
      {
        id: formValue.user,
        data: {
          role: formValue.role,
        },
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return openNotification("Error", e.message)
          openNotification("Success", `User "${selectedUser.email}" role has been updated to ${formValue.role}.`)
          setSelectedUser((prev) => ({ ...prev, role: formValue.role }))
          form.resetFields()
        },
        onError: (e) => {
          openNotification("Error", e.message)
        },
      }
    )
  }
  return (
    <Wrapper>
      <h4>Update User Role</h4>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Form.Item label="Select User Role" name="user" rules={[{ required: true, message: "Please select user" }]}>
          <Select
            placeholder="Select a User"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            loading={isLoading}
            onChange={handleOnChange}
          >
            {data.map((user) => (
              <Select.Option value={user._id} key={user._id}>
                {user.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedUser && (
          <Space direction="vertical" size={10}>
            <Alert showIcon message={`Current Role is : ${capitalizeFirstAlphabet(selectedUser.role)}`} type="info" />
            <Form.Item name="role" initialValue={selectedUser.role}>
              <Select
                allowClear
                placeholder="Select Role"
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option value="seller">Seller</Select.Option>
                <Select.Option value="buyer">Buyer</Select.Option>
                <Select.Option value="admin">admin</Select.Option>
              </Select>
            </Form.Item>
            <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
              Update
            </Button>
          </Space>
        )}
      </Form>

      <ApproveBetaUser />
    </Wrapper>
  )
}
