import { useState } from "react";
import ModelForm from "./ModelForm";
import ModelTable from "./ModelTable";

export default function Brand() {
  const [editId, setEditId] = useState(null);
  const handleEdit = (id) => {
    setEditId(id);
  };
  return (
    <>
      <ModelForm editId={editId} handleEdit={handleEdit} />
      <ModelTable handleEdit={handleEdit} />
    </>
  );
}
