import { useState } from "react"
import ProductOptionForm from "./ProductOptionForm"
import ProductOptionTable from "./ProductOptionTable"

export default function ProductOption() {
  const [editId, setEditId] = useState(null)
  const handleEdit = (id) => {
    setEditId(id)
  }
  return (
    <>
      <ProductOptionForm editId={editId} handleEdit={handleEdit} />
      <ProductOptionTable handleEdit={handleEdit} />
    </>
  )
}
