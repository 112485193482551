import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { ThemeContext } from "../store/themeContext";

const AuthRoute = ({ component: Component, ...rest }) => {
  const user = useContext(ThemeContext);

  const isLoggedIn = user.isLoggedIn;

  return <Route {...rest} render={(props) => (isLoggedIn ? <Redirect to="/dashboard" /> : <Component {...props} />)} />;
};

const RestrictTo = ({ component: Component, ...rest }) => {
  const user = useContext(ThemeContext);
  const isLoggedIn = user.isLoggedIn;

  return <Route {...rest} render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)} />;
};

export { AuthRoute, RestrictTo };
