import React from "react"
import Wrapper from "../../components/Wrapper"
import Dispute from "../../components/dispute"

export default function DisputeExpired() {
  return (
    <Wrapper>
      <Dispute status="expired" name="expire-dispute" path="dispute/admin/expired" />
    </Wrapper>
  )
}
