import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Table, Tag } from "antd"
import { useState } from "react"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"

export default function ProductOptionTable({ handleEdit }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { data, isLoading } = UseData("product-options", "product-option")
  const mutation = useReactQueryMutation("product-options", "product-option")

  const deleteHandel = (id, name) => {
    setConfirmLoading(true)
    mutation.mutate(
      {
        id: id,
        method: "DELETE",
      },
      {
        onSuccess: (e) => {
          openNotification("success", `Product option "${name}" has been deleted.`)
          setVisible(false)
          setConfirmLoading(false)
        },
      }
    )
  }
  const showPopConfirm = (id) => {
    setVisible(id)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },

    {
      title: "Variations",
      dataIndex: "variations",
      key: "variations",
      render: (text) =>
        text.map((variation, i) => (
          <Tag key={`${variation.name}-${i}`} color="cyan">
            {variation.name}
          </Tag>
        )),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Button size="medium" type="primary" shape="circle" icon={<EditOutlined />} onClick={() => handleEdit(record._id)} />
          <Popconfirm
            title={`Are sure you want to delete this "${record.name}" product option?`}
            open={record.key === visible}
            onConfirm={() => deleteHandel(record._id, record.name)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={() => setVisible(false)}
          >
            <Button size="medium" type="primary" danger shape="circle" onClick={() => showPopConfirm(record.key)} icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const tableData = data.map((d) => ({ key: d._id, ...d }))
  if (tableData.length === 0 && !isLoading) return null
  return (
    <div className="pt-5 border-t border-gray-300 mt-8">
      <h1 className="font-bold text-xl pb-3">All Variations</h1>
      <Table columns={columns} dataSource={tableData} loading={isLoading} pagination={{ pageSize: 100 }} />
    </div>
  )
}
