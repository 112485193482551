import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Spin, Tag } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { openNotification } from "../../utils/notification"

export default function Item({ product, name, status, setEditId, showDrawer, handleSelect }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const mutation = useReactQueryMutation(name, "product/one")

  const deleteHandel = (id, name) => {
    setConfirmLoading(id)
    mutation.mutate(
      {
        id: id,
        method: "DELETE",
      },
      {
        onSuccess: (e) => {
          openNotification("success", `Product "${name}" has been deleted.`)
          setVisible(false)
          setConfirmLoading(false)
        },
      }
    )
  }
  const showPopConfirm = (id) => {
    setVisible(id)
  }

  return (
    <div className="bg-white shadow-md px-3 py-2 flex justify-between rounded-lg mb-3 text-center items-center relative">
      <div className="w-52 text-left">{product?.name || "Not defined"}</div>
      <div className="w-44 text-left">{product?.baseProduct?.name}</div>
      <div className="w-40 text-left">{product?.option?.name || "-"}</div>
      <div className="w-40 text-left flex flex-wrap items-start">
        {product?.variations.length > 0
          ? product?.variations.map((v, index) => (
              <Tag color="cyan" key={`${v?.item?.name}-${index}`} className="mb-[3px!important]">
                {v?.item?.name}
              </Tag>
            ))
          : "-"}
      </div>

      <div className="w-20 flex justify-center items-center space-x-2">
        {status === "pending" ? (
          <Button size="medium" type="primary" icon={<CheckOutlined />} shape="circle" onClick={() => handleSelect(product)} />
        ) : (
          <Link to={`/product/edit/${product._id}`}>
            <Button size="medium" type="primary" icon={<EditOutlined />} />
          </Link>
        )}
        <Popconfirm
          title={`Are sure you want to delete this "${product.name}" product?`}
          onConfirm={() => deleteHandel(product._id, product.name)}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={() => setVisible(false)}
        >
          <Button
            loading={product._id === confirmLoading}
            disabled={product._id === confirmLoading}
            size="medium"
            type="primary"
            danger
            shape="circle"
            onClick={() => showPopConfirm(product._id)}
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </div>
      {product._id === confirmLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white/50">
          <Spin size="small" />
        </div>
      )}
    </div>
  )
}
