function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export default isJsonString

export const toFindDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) !== index)
}
