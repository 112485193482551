import { useState, useEffect } from "react"
import { Form, Input, Button, Alert } from "antd"
import { useQueryClient } from "react-query"

import { openNotification } from "../../utils/notification"
import { useReactQueryMutation } from "../../hooks/useMutation"

export default function ProductTypeForm({ editId, handleEdit }) {
  const [form] = Form.useForm()

  const queryClient = useQueryClient()

  const mutation = useReactQueryMutation("productModel", "product-model")

  const submitHandle = async (formValue) => {
    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return

          openNotification("Success", `New product Type "${formValue.name}" has been created.`)
          form.resetFields()
        },
      }
    )
  }

  const cancelEditHandle = () => {
    handleEdit(null)
    form.resetFields()
  }

  const updateHandle = (formValue) => {
    mutation.mutate(
      {
        id: editId,
        data: formValue,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)
          openNotification("Success", `Product Model "${formValue.name}" has been updated.`)

          form.resetFields()
        },
      }
    )
  }

  useEffect(() => {
    if (editId) {
      const [data] = queryClient.getQueryData("productModel").filter((a) => a._id === editId)
      form.setFieldsValue({
        name: data.name,
      })

      return
    }
    form.resetFields()
  }, [editId])

  return (
    <>
      <h1 className="font-bold text-xl">Add Product Model</h1>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={editId ? updateHandle : submitHandle}>
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input product model name!" }]}>
          <Input />
        </Form.Item>

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}
        <Form.Item>
          <div className="flex space-x-4">
            <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
              {editId ? "Update" : "Crate"}
            </Button>
            {editId && (
              <Button type="primary" className="button px-10" danger onClick={cancelEditHandle}>
                Cancel Edit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
