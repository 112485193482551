import { Button, Input, Table, Space, Popover, Typography, Tag } from "antd"
import { useRef, useState } from "react"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import { useQueryClient } from "react-query"
import RefetchButton from "../RefetchButton"
import { dateFormat } from "../../utils/dateFormat"

const { Paragraph } = Typography

export default function WithdrawalTable() {
  const [loading, setLoading] = useState(false)
  const declineInputRef = useRef()
  const { data, isLoading, refetch } = UseData("deposits", "transaction/admin/all?type=deposit")
  const mutation = useReactQueryMutation("deposits", "transaction/admin/deposit/declined")
  const approvedMutation = useReactQueryMutation("deposits", "transaction/admin/deposit/approved")
  const queryClient = useQueryClient()
  const inputRef = useRef(null)

  const handleDecline = async (id) => {
    const reason = declineInputRef.current.resizableTextArea.textArea.value

    if (reason.length === 0) {
      return openNotification("Error", "Declined reason is required")
    }

    mutation.mutate(
      {
        method: "UPDATE",
        id: id,
        data: {
          declinedReason: reason,
        },
      },
      {
        onSuccess: (e) => {
          setLoading(false)
          if (e?.status === "fail") {
            return openNotification("Error", e.message)
          }
          queryClient.setQueryData("deposits", (old) => old.filter((a) => a._id !== e.data._id))
          openNotification("Success", `The user deposit request has been successfully declined.`)
        },
        onError: (e) => {
          openNotification("Error", e.message)
        },
      }
    )
  }

  const handleApprove = async (id) => {
    if (!inputRef.current.input.value) {
      openNotification("Error", "Please provide token.")
      return
    }
    approvedMutation.mutate(
      {
        method: "UPDATE",
        id: id,
        data: {
          token: inputRef.current.input.value,
        },
      },
      {
        onSuccess: (e) => {
          setLoading(false)
          if (e?.status === "fail") {
            return openNotification("Error", e.message)
          }
          openNotification("Success", "You have successfully approved the deposit request.")
          queryClient.setQueryData("withdrawals", (old) => old.filter((a) => a._id !== e.data._id))
        },
        onError: (e) => {
          openNotification("Error", e.message)
        },
      }
    )
  }

  const columns = [
    {
      title: "User ID",
      dataIndex: "users",
      key: "user",
      render: (text, record) => record.users[record.userRole]._id,
    },

    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      render: (text) => text,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => dateFormat(text),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Popover
            content={
              <div className="flex flex-col">
                Are you sure you want to approve this withdrawal.
                <ul className="p-0 list-none pt-5 space-y-3">
                  <li className="flex">
                    <span>Method:</span>
                    <strong className="font-semibold ml-2 mb-0">{record.method}</strong>
                  </li>
                  {record.method === "Wire" || data.method === "ACH" ? (
                    <li>
                      <span>
                        Account type: <strong>{record.paymentDetails.accountType}</strong>
                      </span>
                    </li>
                  ) : null}
                  {record.method !== "Cryptocurrency" && (
                    <li className="flex">
                      <span>Full Name:</span>
                      <Paragraph copyable className="font-semibold ml-2 mb-0">
                        {record.paymentDetails.fullName}
                      </Paragraph>
                    </li>
                  )}
                  {record.method === "Wire" || record.method === "ACH" ? (
                    <>
                      <li className="flex">
                        <span>Account Number:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.paymentDetails.accountNumber}
                        </Paragraph>
                      </li>
                      <li className="flex">
                        <span>Routing Number: </span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.paymentDetails.routingNumber}
                        </Paragraph>
                      </li>
                      <li className="flex">
                        <span>Bank full Address: </span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.paymentDetails.bankFullAddress}
                        </Paragraph>
                      </li>
                    </>
                  ) : null}

                  {record.method === "PayPal" && (
                    <li>
                      <span>PayPal Email: </span>
                      <Paragraph copyable className="font-semibold ml-2">
                        {record.paymentDetails.paypalEmail}
                      </Paragraph>
                    </li>
                  )}
                  {record.method === "Cash App" && (
                    <li className="flex">
                      <span>$Cashtag:</span>
                      <Paragraph copyable className="font-semibold ml-2 mb-0">
                        {record.paymentDetails.cashtag}
                      </Paragraph>
                    </li>
                  )}

                  {record.method === "Cryptocurrency" && (
                    <>
                      <li className="flex">
                        <span>Type:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record?.crypto?.type}
                        </Paragraph>
                      </li>
                      <li className="flex">
                        <span>Network:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record?.crypto?.network}
                        </Paragraph>
                      </li>
                      <li className="flex">
                        <span>Name:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.crypto.name}
                        </Paragraph>
                      </li>
                      <li className="flex">
                        <span>WalletAddress:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.crypto.walletAddress}
                        </Paragraph>
                      </li>
                    </>
                  )}

                  <li className="flex font-semibold items-center py-3">
                    <span>Amount:</span>
                    <span className="ml-2 text-green-500 text-base">$</span>
                    <Paragraph copyable className="text-green-500 text-base" style={{ margin: 0 }}>
                      {record.amount}
                    </Paragraph>
                  </li>

                  <li className="flex">
                    <span>Fee:</span>
                    <span className="ml-2">$</span>
                    <Paragraph copyable className="font-semibold  mb-0">
                      {record.fee.amount}
                    </Paragraph>
                    <span className="opacity-70 ml-5">({record.fee.percentage})</span>
                  </li>
                  <li>
                    <span>
                      total: <strong className="text-red-500">${record.total}</strong>
                    </span>
                  </li>
                </ul>
                <Popover
                  content={
                    <div>
                      <Input ref={inputRef} />
                      <Button className="mt-4" type="primary" onClick={() => handleApprove(record._id)} loading={record._id === loading}>
                        Confirm
                      </Button>
                    </div>
                  }
                  trigger="click"
                  title="Token"
                >
                  <Button type="primary" className="mt-4" loading={record._id === loading}>
                    Approve
                  </Button>
                </Popover>
              </div>
            }
            title="Approve"
            trigger="click"
          >
            <Button type="primary">See Details</Button>
          </Popover>

          <Popover
            content={
              <div>
                <div>
                  <div className="flex flex-col">
                    Are you sure you want to decline this withdrawal.
                    <ul className="p-0 list-none pt-5 space-y-3">
                      <li className="flex">
                        <span>Method:</span>
                        <strong className="font-semibold ml-2 mb-0">{record.method}</strong>
                      </li>
                      {record.method === "Wire" || data.method === "ACH" ? (
                        <li>
                          <span>
                            Account type: <strong>{record.paymentDetails.accountType}</strong>
                          </span>
                        </li>
                      ) : null}
                      <li className="flex">
                        <span>Full Name:</span>
                        <Paragraph copyable className="font-semibold ml-2 mb-0">
                          {record.paymentDetails.fullName}
                        </Paragraph>
                      </li>
                      {record.method === "Wire" || data.method === "ACH" ? (
                        <>
                          <li className="flex">
                            <span>Account Number:</span>
                            <Paragraph copyable className="font-semibold ml-2 mb-0">
                              {record.paymentDetails.accountNumber}
                            </Paragraph>
                          </li>
                          <li className="flex">
                            <span>Routing Number: </span>
                            <Paragraph copyable className="font-semibold ml-2 mb-0">
                              {record.paymentDetails.routingNumber}
                            </Paragraph>
                          </li>
                          <li className="flex">
                            <span>Bank full Address: </span>
                            <Paragraph copyable className="font-semibold ml-2 mb-0">
                              {record.paymentDetails.bankFullAddress}
                            </Paragraph>
                          </li>
                        </>
                      ) : null}

                      {record.method === "PayPal" && (
                        <li>
                          <span>PayPal Email: </span>
                          <Paragraph copyable className="font-semibold ml-2">
                            {record.paymentDetails.paypalEmail}
                          </Paragraph>
                        </li>
                      )}
                      {record.method === "Cash App" && (
                        <li className="flex">
                          <span>$Cashtag:</span>
                          <Paragraph copyable className="font-semibold ml-2 mb-0">
                            {record.paymentDetails.cashtag}
                          </Paragraph>
                        </li>
                      )}

                      <li className="flex font-semibold items-center py-3">
                        <span>Amount:</span>
                        <span className="ml-2 text-green-500 text-base">$</span>
                        <Paragraph copyable className="text-green-500 text-base" style={{ margin: 0 }}>
                          {record.amount}
                        </Paragraph>
                      </li>

                      <li className="flex">
                        <span>Fee:</span>
                        <span className="ml-2">$</span>
                        <Paragraph copyable className="font-semibold  mb-0">
                          {record.fee.amount}
                        </Paragraph>
                        <span className="opacity-70 ml-5">({record.fee.percentage})</span>
                      </li>
                      <li>
                        <span>
                          total: <strong className="text-red-500">${record.total}</strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <Space direction="vertical" style={{ display: "flex" }}>
                    <div>Declined Reason</div>
                    <Input.TextArea rows={4} ref={declineInputRef} maxLength={200} showCount={true} />
                  </Space>
                </div>
                <Button danger className="mt-4" onClick={() => handleDecline(record._id)} loading={record._id === loading}>
                  Decline
                </Button>
              </div>
            }
            title="Decline"
            trigger="click"
          >
            <Button danger loading={record._id === loading}>
              Decline
            </Button>
          </Popover>
        </Space>
      ),
    },
  ]
  const tableData = data.map((d) => ({ key: d._id, ...d }))
  if (tableData.length === 0 && !isLoading) {
    return (
      <>
        <RefetchButton refetch={refetch} loading={loading} name="Withdrawal" className="relative" />
        <h1 className="mt-5">No pending withdrawal found!</h1>
      </>
    )
  }
  return (
    <div className="pt-5 border-t border-gray-300 mt-8">
      <h1 className="font-bold text-xl pb-3">All Pending withdrawal</h1>

      <RefetchButton refetch={refetch} loading={loading} name="Withdrawal" className="relative" />
      <Table columns={columns} dataSource={tableData} loading={isLoading} className="mt-5" pagination={{ pageSize: 100 }} />
    </div>
  )
}
