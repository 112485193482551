import { useState } from "react"
import AnnouncementForm from "./announcementFrom"
import AnnouncementTable from "./announcementTable"

export default function Announcement() {
  const [editId, setEditId] = useState(null)
  const handleEdit = (id) => {
    setEditId(id)
  }
  return (
    <>
      <AnnouncementForm editId={editId} handleEdit={handleEdit} />
      <AnnouncementTable handleEdit={handleEdit} />
    </>
  )
}
