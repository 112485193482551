import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Alert, Button, Col, Form, Input, Row, Select, Spin, Switch } from "antd"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import API from "../../helper/api"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import FileUpload from "../FileUpload"
import RefetchButton from "../RefetchButton"
import { useQueryClient } from "react-query"

const BaseProductSelectOption = ({ categoryId }) => {
  const { data, isLoading, refetch } = UseData("baseProductSelect", `base-product/category/${categoryId}`)
  useEffect(() => {
    refetch()
  }, [categoryId, refetch])

  if (data.length === 0) return null
  return (
    <Form.Item
      name="baseProduct"
      label="Base Product"
      rules={[
        {
          required: true,
          message: "Please select Base Product",
        },
      ]}
    >
      <Select
        placeholder="Select base product"
        allowClear
        showSearch
        loading={isLoading}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {data.map((d) => (
          <Select.Option value={d._id} key={d._id}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const VariationSelectOption = ({ categoryId }) => {
  const { data, isLoading, refetch } = UseData("variationSelect", `variation/category/${categoryId}`)
  useEffect(() => {
    //if (data.length > 0 && categoryId === data[0].category) return;
    refetch()
  }, [categoryId, refetch])

  if (data.length === 0) return null
  return (
    <Form.List name="variations">
      {(fields, { add, remove }) => (
        <>
          <label className="block mb-4 text-black">Variations</label>
          <div className="flex flex-wrap relative -mx-4">
            {fields.map((field) => (
              <div key={field.key} className="w-1/2 px-4 flex flex-wrap option-outer relative pr-7">
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.variation !== curValues.variation}>
                  {() => (
                    <Form.Item
                      {...field}
                      label="Variation"
                      name={[field.name]}
                      rules={[
                        {
                          required: true,
                          message: "Missing variation",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a Variation"
                        allowClear
                        showSearch
                        loading={isLoading}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {data.map((d) => (
                          <Select.Option value={d._id} key={d._id}>
                            {d.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>

                <div className="bg-red-600 w-7 h-7 text-base rounded-full text-white flex flex-col items-center justify-center absolute right-0 top-[34px] px-0 button-dv">
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </div>
              </div>
            ))}
          </div>
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Variation
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

const VariationValue = ({ selectedBaseProduct, handleChange, field, variationName }) => {
  const variationItem = variationName

  return (
    <Form.Item
      name={[field.name, "value"]}
      label="Variation Value"
      rules={[
        {
          required: true,
          message: "Please select variation value",
        },
      ]}
    >
      <Select
        placeholder="Select a variation"
        allowClear
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        onChange={(d) => handleChange("variationValue", d)}
      >
        {variationItem?.items?.map((d) => (
          <Select.Option value={JSON.stringify(d)} key={d.name}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const VariationItem = ({ field, remove, handleChange, form, selectedProduct }) => {
  const { baseProduct, option } = selectedProduct
  const [variationName, setVariationName] = useState(false)

  const handleOneChange = (e) => {
    if (!e) return
    setVariationName(JSON.parse(e))
    handleChange("variationName", e)
  }

  const selectedOption = baseProduct.options.find((b) => b.name === option.name)

  const variations = baseProduct.hasOption ? selectedOption?.variations : baseProduct.variations

  return (
    <div className="relative pr-14 flex space-x-4">
      <div className="w-1/2">
        <Form.Item
          {...field}
          label="Select Variation name"
          name={[field.name, "name"]}
          key={`value-${field.key}`}
          rules={[
            {
              required: true,
              message: "Missing value name",
            },
          ]}
        >
          <Select
            placeholder="Select value"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onChange={handleOneChange}
          >
            {variations?.map((v) => (
              <Select.Option value={JSON.stringify(v)} key={v.name}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {variationName && (
        <div className="w-1/2">
          <VariationValue selectedProduct={selectedProduct} variationName={variationName} field={field} handleChange={handleChange} />
        </div>
      )}
      <MinusCircleOutlined className="absolute right-3 top-14 text-xl text-red-500" onClick={() => remove(field.name)} />
    </div>
  )
}

const OptionSelect = ({ selectedProduct, handleChange, form }) => {
  const { baseProduct } = selectedProduct
  const { data, isLoading, isError, error, refetch, isRefetching } = UseData(`option-${baseProduct?._id}`, `base-product/option/${baseProduct?._id}`)

  if (!baseProduct) return null

  if (isError) return <div>{error.message}</div>

  if (!isLoading && data.length === 0) return <h3 className="text-orange-300">No option found please create option if needed</h3>
  return (
    <div className="relative pr-14">
      <Form.Item name="option" label="Option Name" className="relative">
        <Select
          placeholder="Select a option"
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          onChange={(m) => handleChange("option", m)}
          loading={isLoading}
        >
          {data?.map((d) => (
            <Select.Option value={JSON.stringify(d)} key={d.name}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <RefetchButton refetch={refetch} name="Option" loading={isLoading || isRefetching} />
    </div>
  )
}

export default function EditProduct() {
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(true)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [imageName, setImageName] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState()
  const [form] = Form.useForm()
  const mutation = useReactQueryMutation("products", "product/update", file)

  const { data, isLoading } = UseData("base-products", "base-product")
  const queryClient = useQueryClient()
  const params = useParams()
  const history = useHistory()

  const setFileHandle = (e) => {
    setFileSet(true)
    setFile(e)
  }
  const submitHandle = async (formValue) => {
    const { baseProduct, option, variations, ...rest } = formValue

    const parsedBaseProduct = selectedProduct.baseProduct

    const sanitizedOjb = {
      baseProduct: parsedBaseProduct.slug,
      buyOrderId: selectedProduct._id,
      ...rest,
    }

    // if model exist
    if (option) {
      const parsedOption = JSON.parse(option)
      sanitizedOjb["option"] = {
        name: parsedOption.name,
        nameId: parsedOption.nameId,
        optionKeyName: parsedOption.optionKeyName,
        optionKeyId: parsedOption.optionKeyId,
      }
    }

    // parse all the variations
    if (variations.length > 0) {
      const parsedVariations = variations.map((v) => {
        const name = JSON.parse(v.name)
        const value = JSON.parse(v.value)

        return {
          name: name.name,
          nameId: name.nameId,
          item: {
            colorCode: value?.colorCode,
            nameId: value.nameId,
            name: value.name,
          },
        }
      })

      sanitizedOjb["variations"] = parsedVariations
    }

    if (rest.useImageToBaseProduct && sanitizedOjb.variations.length > 1) {
      return openNotification("Error", "This Product only allows single variation.")
    }

    setSuccess(false)

    mutation.mutate(
      {
        method: "UPDATE",
        id: params.id,
        data: sanitizedOjb,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return openNotification("Error", e.message)
          if (Object.keys(file).length > 0) {
            setSuccess(true)
          }
          openNotification("Success", `Product "${formValue.name}" has been updated.`)
          form.resetFields()
          setImageName(null)
          setFile({})
          queryClient.invalidateQueries(["active-products"])
          setTimeout(() => {
            history.push("/product/active")
          }, 500)
        },
        onError: (e) => {
          return openNotification("Error", e.message)
        },
      }
    )
    setFileSet(true)
  }

  useEffect(() => {
    async function initialRun() {
      setLoading(true)
      const product = await new API().fetchData(`product/one/${params.id}`)

      setLoading(false)
      if (!product) {
        history.push("/product/all")
      }

      setLoading(false)

      setImageName(product?.images?.medium.name)

      setSelectedProduct(product)

      form.setFieldsValue({
        name: product.name,
        retailPrice: product.retailPrice,
        baseProduct: product.baseProduct.slug,
        upc: product.upc,
        description: product.description,
        // option: product.option.name,
        // variations: product.variations,
      })
    }
    initialRun()
  }, [])

  useEffect(() => {
    let title = ""
    if (selectedProduct?.baseProduct?.brand) {
      title += selectedProduct?.baseProduct?.brand + " - " + selectedProduct.baseProduct.name
    }
    if (selectedProduct?.option) {
      title += " - " + selectedProduct?.option?.name
    }
    if (selectedProduct?.variationValue) {
      Object.keys(selectedProduct.variationValue).forEach((v) => {
        title += " - " + selectedProduct.variationValue[v]
      })
    }

    form.setFieldsValue({
      name: title,
    })
  }, [selectedProduct])

  const handleChange = (key, item) => {
    if (key === "baseProduct") {
      form.setFieldsValue({
        variations: "",
        option: "",
      })
      const parsedData = JSON.parse(item)

      setSelectedProduct((prev) => ({ ...prev, baseProduct: parsedData }))
      return
    }
    if (key === "option") {
      form.setFieldsValue({
        variations: "",
      })
      if (item) {
        const parsedData = JSON.parse(item)
        setSelectedProduct((prev) => ({ ...prev, [key]: parsedData }))
      }

      return
    }
    if (key === "variationName") {
      const parsedData = JSON.parse(item)
      setSelectedProduct((prev) => ({ ...prev, [key]: parsedData }))
      return
    }
    if (key === "variationValue") {
      const parsedData = JSON.parse(item)

      if (selectedProduct.variationValue) {
        return setSelectedProduct((prev) => ({
          ...prev,
          variationValue: { ...prev.variationValue, [selectedProduct.variationName.name]: parsedData.name },
        }))
      }
      return setSelectedProduct((prev) => ({ ...prev, variationValue: { [selectedProduct.variationName.name]: parsedData.name } }))
    }
  }

  if (loading)
    return (
      <div className="inset-0 flex justify-center items-center absolute  bg-white/80 z-10 min-h-[500px]">
        <Spin />
      </div>
    )

  return (
    <>
      <h1 className="font-bold text-xl">Add Product</h1>

      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        {!isLoading && (
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label="Product Name" name="name" rules={[{ required: true, message: "Please input brand name!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="upc"
                label="Upc"
                rules={[
                  {
                    required: true,
                    message: "upc is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="baseProduct"
                label="Base Product"
                rules={[
                  {
                    required: true,
                    message: "Please select base product",
                  },
                ]}
              >
                <Select
                  placeholder="Select a base product"
                  allowClear
                  showSearch
                  onChange={(e) => handleChange("baseProduct", e)}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {data.map((d) => (
                    <Select.Option value={JSON.stringify(d)} key={d._id}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="retailPrice"
                label="Retail Price"
                rules={[
                  {
                    required: true,
                    message: "Retail price is required.",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="TextArea" name="description" rules={[{ required: true, message: "Product description is required." }]}>
                <Input.TextArea rows={4} className="w-full" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedProduct.baseProduct.hasOption && (
          <>
            <OptionSelect selectedProduct={selectedProduct} handleChange={handleChange} form={form} />
            {selectedProduct.baseProduct.options && (
              <Form.List name="variations">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, i) => (
                      <VariationItem
                        handleChange={handleChange}
                        key={`variation-${i}`}
                        field={field}
                        remove={remove}
                        selectedProduct={selectedProduct}
                        form={form}
                      />
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Variation
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            )}
          </>
        )}

        {selectedProduct.baseProduct.hasVariation && !selectedProduct.baseProduct.hasOption ? (
          <>
            <Form.List name="variations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, i) => (
                    <VariationItem
                      handleChange={handleChange}
                      key={`variation-${i}`}
                      field={field}
                      remove={remove}
                      selectedProduct={selectedProduct}
                      form={form}
                    />
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Variation
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </>
        ) : null}

        <FileUpload setFileHandle={setFileHandle} success={success} image={imageName} />

        {selectedProduct.baseProduct.hasVariation && !selectedProduct.baseProduct.hasOption && Object.keys(file).length > 0 ? (
          <Form.Item name="useImageToBaseProduct" label="Use this image to base Product" valuePropName="checked">
            <Switch />
          </Form.Item>
        ) : null}

        {!fileSet && (
          <div className="mb-5">
            <Alert message="Please select the image!" type="error" showIcon />
          </div>
        )}

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}

        <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
          Add and Upload
        </Button>
      </Form>
    </>
  )
}
