import { Spin } from "antd"
import { useState, Fragment } from "react"
import { useInfinityFetchQuery } from "../../../hooks/useReactQuery"
import Item from "../Item"
import TopMenu from "../TopMenu"
import DrawerSection from "./DrawerSection"
import { LoadMoreButton } from "../../Button"

export default function Product({ status, name }) {
  const { data, isFetchingNextPage, isLoading, isError, error, fetchNextPage, hasNextPage } = useInfinityFetchQuery(
    name,
    "product",
    status.toLocaleLowerCase()
  )

  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [editId, setEditId] = useState(null)

  const showDrawer = (id) => {
    setOpen(true)
    setEditId(id)
  }

  const handleSelect = (selectedItm) => {
    setSelectedProduct(selectedItm)
    setOpen(true)
  }
  if (isError) {
    return <p className="px-8 py-10 font-bold">{error.message}</p>
  }

  if (isLoading) {
    return (
      <div className="flex justify-center py-10">
        <Spin />
      </div>
    )
  }

  return (
    <div className="relative">
      <TopMenu />
      {(!isLoading && !data) || (!isLoading && data.pages.length === 1 && data.pages[0].data.length === 0) ? (
        "No Product found"
      ) : (
        <>
          <DrawerSection setOpen={setOpen} open={open} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
          {data.pages.map((page, i) => (
            <Fragment key={`receiving-card-page-${i}`}>
              {page.data.map((product, index) => (
                <Item
                  key={product._id}
                  product={product}
                  name={name}
                  status={status}
                  showDrawer={showDrawer}
                  setEditId={setEditId}
                  handleSelect={handleSelect}
                />
              ))}
            </Fragment>
          ))}
          <LoadMoreButton
            pages={data.pages}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            fetchNextPage={fetchNextPage}
          />
        </>
      )}
    </div>
  )
}
