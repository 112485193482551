import React, { useState } from "react"
import { Button, Modal, Form, Tabs } from "antd"
import { useReactQueryMutation } from "../../../hooks/useMutation"
import { openNotification } from "../../../utils/notification"

import EditVariation from "./EditVariation"
import AddVariation from "./addVariation"
import Delete from "./Delete"

export default function VariationModal({ modalVisible, setModalVisible, selectedOption, setSelectedOption }) {
  const [activeTab, setActiveTab] = useState("addNew")
  const [file, setFile] = useState({})
  const [success, setSuccess] = useState(false)
  const [imageName, setImageName] = useState(null)

  const mutation = useReactQueryMutation("base-products", "base-product", file)

  const [form] = Form.useForm()

  const setFileHandle = (e) => {
    setFile(e)
  }

  const handleOnChange = (e) => {
    setActiveTab(e)
    setImageName(e)
    setFile({})
    setSuccess(true)
    setSelectedOption((prev) => ({ baseProduct: prev.baseProduct, brand: prev.brand, productType: prev.productType, category: prev.category }))

    form.setFieldsValue({
      name: [],
      value: "",
    })
  }

  const submitHandle = async (formValue) => {
    const parsedObj = {}

    Object.keys(formValue).forEach((el) => {
      if (formValue[el] === "" || !formValue[el]) return

      parsedObj[el] = JSON.parse(formValue[el])
    })

    const variation = {
      name: parsedObj.name?.name,
      nameId: activeTab === "addNew" ? parsedObj?.name?._id : parsedObj?.name?.nameId,
      item: {
        name: parsedObj.value.name,
        colorCode: parsedObj?.value?.colorCode,
        nameId: activeTab === "addNew" ? parsedObj.value._id : parsedObj.value.nameId,
      },
    }

    mutation.mutate(
      {
        id: `variation/${selectedOption.baseProduct._id}`,
        data: variation,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") {
            openNotification("Error", e.message)
            return
          }
          openNotification("Success", `Base Product "${selectedOption?.baseProduct?.name}" has been updated.`)
          form.resetFields()
          setImageName(null)
          setFile({})
          setSuccess(true)
        },
      }
    )
  }

  return (
    <Modal
      title={`${activeTab ? "Adding" : "Editing"} Variation to ${selectedOption["baseProduct"].name}`}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      width={1200}
      okButtonProps={{ hidden: true }}
    >
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Tabs
          defaultActiveKey="addNew"
          type="card"
          animated={{ tabPane: true }}
          items={[
            {
              key: "addNew",
              label: "Add New",
              children: (
                <AddVariation
                  form={form}
                  success={success}
                  imageName={imageName}
                  setFileHandle={setFileHandle}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setImageName={setImageName}
                />
              ),
            },
            {
              key: "edit",
              label: "Edit",
              children: (
                <EditVariation
                  form={form}
                  success={success}
                  imageName={imageName}
                  setFileHandle={setFileHandle}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  setImageName={setImageName}
                />
              ),
            },

            {
              key: "delete",
              label: "Delete",
              children: <Delete selectedOption={selectedOption} setSelectedOption={setSelectedOption} />,
            },
          ]}
          onChange={handleOnChange}
        />
        {activeTab !== "delete" && (
          <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
            Submit
          </Button>
        )}
      </Form>
    </Modal>
  )
}
