import React from "react";

import Wrapper from "../../components/Wrapper";
import AddProduct from "../../components/product/AddProduct";
export default function AddPage() {
  return (
    <Wrapper>
      <AddProduct />
    </Wrapper>
  );
}
