import React from "react"
import Wrapper from "../../components/Wrapper"
import AddModel from "../../components/baseProduct/addOption"
export default function AddOptionPage() {
  return (
    <Wrapper>
      <AddModel />
    </Wrapper>
  )
}
