import { Alert, Button, Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import FileUpload from "../FileUpload"

export default function BaseProductForm({ editId, handleEdit }) {
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(true)
  const [success, setSuccess] = useState(false)
  const [imageName, setImageName] = useState(null)
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const mutation = useReactQueryMutation("base-products", "base-product", file)
  const category = UseData("category", "category")
  const productType = UseData("product-types", "product-type")
  const brand = UseData("brand", "brand")

  const setFileHandle = (e) => {
    setFileSet(true)
    setFile(e)
  }
  const submitHandle = async (formValue) => {
    setSuccess(false)

    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)
          if (Object.keys(file).length > 0) {
            setSuccess(true)
          }
          openNotification("Success", `New Base product "${formValue.name}" has been created.`)
          form.resetFields()
          setFile({})
        },
      }
    )
    setFileSet(true)
  }

  const cancelEditHandle = () => {
    handleEdit(null)
    form.resetFields()
  }

  const updateHandle = (formValue) => {
    mutation.mutate(
      {
        id: editId,
        data: formValue,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)
          openNotification("Success", `Base Product "${formValue.name}" has been updated.`)
          setSuccess(true)
          handleEdit(null)
          form.resetFields()
          setFile({})
          setImageName(null)
        },
      }
    )
  }

  useEffect(() => {
    if (editId) {
      const [data] = queryClient.getQueryData("base-products")?.filter((a) => a._id === editId)
      form.setFieldsValue({
        name: data.name,
        slug: data.slug,
        category: data.category,
        brand: data.brand,
        productType: data.productType,
      })
      setImageName(data.image)
      return
    }

    setImageName()
  }, [editId, category])
  return (
    <>
      <h1 className="font-bold text-xl">Add Base Product</h1>

      <Form layout="vertical" name="basic" size="large" form={form} onFinish={editId ? updateHandle : submitHandle}>
        <Row gutter={20}>
          <Col span={8}>
            {!category.isLoading && (
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "Please select category",
                  },
                ]}
                initialValue={category?.data[0]?.slug}
              >
                <Select
                  placeholder="Select a category"
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {category.data.map((d) => (
                    <Select.Option value={d.slug} key={d._id} className="capitalize">
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span={8}>
            <Form.Item
              name="productType"
              label="Product Type"
              rules={[
                {
                  required: true,
                  message: "Please select product type.",
                },
              ]}
            >
              <Select
                placeholder="Select a product type"
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {productType.data.map((d) => (
                  <Select.Option value={d.slug} key={d._id} className="capitalize">
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="brand"
              label="Brand"
              rules={[
                {
                  required: true,
                  message: "Please select brand",
                },
              ]}
            >
              <Select
                placeholder="Select a brand"
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {brand.data.map((d) => (
                  <Select.Option value={d.slug} key={d._id} className="capitalize">
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item label="Base Product Name" name="name" rules={[{ required: true, message: "Please input brand name!" }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <FileUpload setFileHandle={setFileHandle} image={imageName} success={success} />

        {!fileSet && (
          <div className="mb-5">
            <Alert message="Please select the image!" type="error" showIcon />
          </div>
        )}

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}
        <Form.Item>
          <div className="flex space-x-4">
            <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
              {editId ? "Update and Upload" : "Crate and Upload"}
            </Button>
            {editId && (
              <Button type="primary" className="button px-10" danger onClick={cancelEditHandle}>
                Cancel Edit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
