import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Spin, Tag } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { openNotification } from "../../utils/notification"
import { dateFormat } from "../../utils/dateFormat"

export default function Item({ data, name, status, setEditId, showDrawer, page }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const mutation = useReactQueryMutation(name, "product/one")

  const deleteHandel = (id, name) => {
    setConfirmLoading(id)
    mutation.mutate(
      {
        id: id,
        method: "DELETE",
      },
      {
        onSuccess: (e) => {
          openNotification("success", `Product "${name}" has been deleted.`)
          setVisible(false)
          setConfirmLoading(false)
        },
      }
    )
  }
  const showPopConfirm = (id) => {
    setVisible(id)
  }

  return (
    <div className="bg-white border border-gray-300 shadow px-3 py-2 flex justify-between rounded-lg mb-3 text-center items-center relative">
      <div className="w-52 text-left">{data?.uId}</div>
      <div className="w-48 text-left">{dateFormat(data?.createdAt)}</div>
      <div className="w-44 text-left capitalize">{data?.reasons[0]?.title}</div>

      <div className="w-28 flex space-x-2">-</div>
      <div className="w-20">
        <Button size="medium" type="primary" onClick={() => setEditId({ id: data._id, page })}>
          View
        </Button>
      </div>
      {data._id === confirmLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white/50">
          <Spin size="small" />
        </div>
      )}
    </div>
  )
}
