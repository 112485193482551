import React, { useState } from "react"
import { Button, Modal, Form, Tabs } from "antd"
import { useReactQueryMutation } from "../../../hooks/useMutation"
import { openNotification } from "../../../utils/notification"
import EditOption from "./EditOption"
import AddOption from "./AddOption"
import isJsonString from "../../../utils/isJsonString"

export default function OptionModal({ modalVisible, setModalVisible, selectedOption, setSelectedOption }) {
  const [addNew, setAddNew] = useState("addNew")
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(true)
  const [success, setSuccess] = useState(false)
  const [imageName, setImageName] = useState(null)

  const mutation = useReactQueryMutation("base-products", "base-product", file)

  const [form] = Form.useForm()

  const setFileHandle = (e) => {
    setFileSet(true)
    setFile(e)
  }

  const handleOnChange = (e) => {
    setAddNew(e)
    setImageName(e)
    setFile({})
    setSuccess(true)
    setSelectedOption((prev) => ({ baseProduct: prev.baseProduct, brand: prev.brand, productType: prev.productType, category: prev.category }))
    form.setFieldsValue({
      variations: [],
      variationName: "",
      optionName: "",
      optionValue: "",
    })
  }

  const submitHandle = async (formValue) => {
    const parsedObj = {}

    Object.keys(formValue).forEach((el) => {
      if (formValue[el] === "" || !formValue[el]) return
      if (el === "variations") {
        return (parsedObj[el] = formValue.variations.map((v) => (v.name.indexOf("{") >= 0 ? JSON.parse(v.name) : v)))
      }

      if (isJsonString(formValue[el])) {
        parsedObj[el] = JSON.parse(formValue[el])
        return
      }
      parsedObj[el] = formValue[el]
    })
    const typeArr = ["string", "number"]
    const option = {
      optionKeyName: addNew === "addNew" ? parsedObj?.optionName?.name : parsedObj.optionName?.optionKeyName,
      optionKeyId: addNew === "addNew" ? parsedObj?.optionName?._id : parsedObj?.optionName?.optionKeyId,
      name: typeArr.includes(typeof parsedObj.optionValue) ? parsedObj?.optionValue.toString() : parsedObj.optionValue?.name.toString(),
      //addNew === "addNew" ? parsedObj.optionValue?.name : parsedObj?.optionValue,
      nameId: addNew === "addNew" ? parsedObj?.optionValue?._id : parsedObj?.optionName?.nameId,
    }

    if (parsedObj.variationName) {
      option["variations"] = {
        name: parsedObj.variationName.name,
        nameId: parsedObj?.variationName?.nameId || parsedObj.variationName._id,
        items: parsedObj.variations.map((v) => {
          return {
            nameId: v?.nameId || v._id,
            name: v.name,
            colorCode: v?.colorCode,
          }
        }),
      }
    }

    mutation.mutate(
      {
        id: `option/${selectedOption.baseProduct._id}`,
        data: option,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") {
            openNotification("Error", e.message)
            return
          }
          openNotification("Success", `Base Product "${selectedOption?.baseProduct?.name}" has been updated.`)
          form.resetFields()
          setImageName(null)
          setFile({})
          setSuccess(true)
        },
      }
    )
  }

  return (
    <Modal
      title={`${addNew ? "Adding" : "Editing"} Model to ${selectedOption["baseProduct"].name}`}
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      width={1200}
      okButtonProps={{ hidden: true }}
    >
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Tabs
          defaultActiveKey="addNew"
          type="card"
          animated={{ tabPane: true }}
          items={[
            {
              key: "addNew",
              label: "Add New",
              children: (
                <AddOption
                  form={form}
                  success={success}
                  imageName={imageName}
                  setFileHandle={setFileHandle}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              ),
            },
            {
              key: "edit",
              label: "Edit",
              children: (
                <EditOption
                  form={form}
                  success={success}
                  imageName={imageName}
                  setFileHandle={setFileHandle}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              ),
            },
          ]}
          onChange={handleOnChange}
        />

        <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
          Submit
        </Button>
      </Form>
    </Modal>
  )
}
