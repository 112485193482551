import React from "react"
import Wrapper from "../../components/Wrapper"
import BaseProduct from "../../components/baseProduct"

export default function BaseProductPage() {
  return (
    <Wrapper>
      <BaseProduct />
    </Wrapper>
  )
}
