import { useState } from "react"
import { Button, Space, Tag } from "antd"
import FindBaseProduct from "./FindBaseProduct"
import VariationModal from "../Variation/VariationModal"
import OptionModal from "./OptionModel"

export default function AddOptionWrapper() {
  const [modalVisible, setModalVisible] = useState(false)
  const [variationModal, setVariationModal] = useState(false)

  const [selectedOption, setSelectedOption] = useState({})
  return (
    <>
      <FindBaseProduct model={true} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

      {Object.keys(selectedOption).length > 3 && (
        <>
          <Space>
            {(!selectedOption.baseProduct.hasVariation && !selectedOption.baseProduct.hasOption) ||
            (!selectedOption.baseProduct.hasVariation && selectedOption.baseProduct.hasOption) ? (
              <Button type="primary" onClick={() => setModalVisible(true)} size="large">
                Add Model on {selectedOption["baseProduct"].name}
              </Button>
            ) : null}
            {!selectedOption.baseProduct.hasVariation && !selectedOption.baseProduct.hasOption && (
              <div className="mx-5">
                <Tag color="black">OR</Tag>
              </div>
            )}

            {(!selectedOption.baseProduct.hasVariation && !selectedOption.baseProduct.hasOption) ||
            (selectedOption.baseProduct.hasVariation && !selectedOption.baseProduct.hasOption) ? (
              <Button type="primary" onClick={() => setVariationModal(true)} size="large">
                Add Variation on {selectedOption["baseProduct"].name}
              </Button>
            ) : null}
          </Space>
          <OptionModal
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <VariationModal
            setModalVisible={setVariationModal}
            modalVisible={variationModal}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </>
      )}
    </>
  )
}
