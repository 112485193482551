import React from "react"
import Withdrawal from "../../components/withdrawal"
import Wrapper from "../../components/Wrapper"
export default function WithdrawalPage() {
  return (
    <Wrapper>
      <Withdrawal />
    </Wrapper>
  )
}
