import React from "react"
import Deposit from "../../components/deposit"
import Wrapper from "../../components/Wrapper"
export default function DepositPage() {
  return (
    <Wrapper>
      <Deposit />
    </Wrapper>
  )
}
