import React, { useState } from "react"
import Wrapper from "../../components/Wrapper"
import FindBaseProduct from "../../components/baseProduct/addOption/FindBaseProduct"
import DelateTable from "../../components/baseProduct/deleteModel/DelateTable"
export default function DeleteModelPage() {
  const [selectedOption, setSelectedOption] = useState({})
  return (
    <Wrapper>
      <FindBaseProduct model={false} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
      {Object.keys(selectedOption).length > 3 && <DelateTable selectedOption={selectedOption} />}
    </Wrapper>
  )
}
