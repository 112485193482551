import React from "react"

export default function TopMenu() {
  return (
    <div className="bg-blue-600 text-white rounded-lg py-3 px-3 flex justify-between mb-3 text-center">
      <div className="w-52 text-left">Name</div>
      <div className="w-44 text-left">Base Product</div>
      <div className="w-40 text-left">Option</div>
      <div className="w-40 text-left">Variation</div>
      <div className="w-20 text-left">Action</div>
    </div>
  )
}
