import React from "react"
import Wrapper from "../components/Wrapper"
import Retailer from "../components/retailer"
export default function RetailerPage() {
  return (
    <Wrapper>
      <Retailer />
    </Wrapper>
  )
}
