import React from "react";
import Wrapper from "../components/Wrapper";
import Model from "../components/model";
export default function ModelPage() {
  return (
    <Wrapper>
      <Model />
    </Wrapper>
  );
}
