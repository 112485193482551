import React from "react";

import Wrapper from "../../components/Wrapper";
import EditProduct from "../../components/product/EditProduct";
export default function EditPage() {
  return (
    <Wrapper>
      <EditProduct />
    </Wrapper>
  );
}
