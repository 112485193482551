import { Spin } from "antd"

export const LoadMoreButton = ({ pages, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading }) => {
  if (pages[0]?.result === pages[0]?.totalDoc) return null
  if (!hasNextPage) return null
  return (
    <div className="mt-5 flex justify-center">
      <button
        className="bg-blue-500 px-10 py-4 cursor-pointer rounded border-0 outline-none relative bg-blue-1020 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-50"
        onClick={() => fetchNextPage()}
        disabled={isFetchingNextPage || !hasNextPage || isLoading}
      >
        {isFetchingNextPage ? "Fetching" : "Load more"}
        {isFetchingNextPage && (
          <div className="absolute inset-0 flex justify-center items-center">
            <Spin />
          </div>
        )}
      </button>
    </div>
  )
}
