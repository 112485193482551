import { Form } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { getImagePath } from "./../utils/uri"

export default function FileUpload({ setFileHandle, image, success, size = "w-28", label, path }) {
  const [file, setFile] = useState({})

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
      setFileHandle(acceptedFiles[0])
      setFile(acceptedFiles[0])
    },
    [image, success]
  )

  useEffect(() => {
    if (success) {
      setFile({})
    }
  }, [success, image])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/webp": [],
    },
  })
  return (
    <Form.Item label={label ? label : "Upload"} rules={[{ required: true, message: "Please select image" }]}>
      <div
        {...getRootProps()}
        className="border border-dashed border-gray-300 bg-gray-100 px-5 py-7 cursor-pointer flex justify-center flex-col items-center"
      >
        <input {...getInputProps()} />
        {/* <AiOutlineInbox className="text-5xl mb-5" /> */}
        {isDragActive ? <p>Drop the files here ...</p> : <p>Click or drag photo to this area to upload</p>}
      </div>
      {Object.keys(file).length > 0 && (
        <figure className={`mt-3 ${size} border border-gray-300`}>
          <img src={file.preview} alt="" className="p-1 max-w-full" />
        </figure>
      )}
      {Object.keys(file).length === 0 && image && (
        <figure className={`mt-3 ${size}`}>
          <img src={getImagePath(path || image)} className="border border-gray-300 p-1 max-w-full" alt="" />
        </figure>
      )}
    </Form.Item>
  )
}
