import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Alert, Button, Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import VariationUpload from "../VariationUpload"
import API from "../../helper/api"
import { toFindDuplicates } from "../../utils/isJsonString"

function ModelView({ baseProductId }) {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData(`model-${baseProductId}`)
  if (!baseProductId) return null
  return (
    <Col span={12}>
      <Form.Item
        name="model"
        label="Model"
        rules={[
          {
            required: true,
            message: "Please select model",
          },
        ]}
      >
        <Select
          placeholder="Select a model"
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {data?.options?.map((d) => (
            <Select.Option value={d} key={d} className="capitalize">
              {d}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  )
}

export default function ProductOptionForm({ editId, handleEdit }) {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const mutation = useReactQueryMutation("product-options", "product-option")

  const [uploadPercentage, setUploadPercentage] = useState([])
  const [editedData, setEditedData] = useState(null)

  const submitHandle = async (formValue) => {
    if (formValue.variations && toFindDuplicates(formValue.variations.map((v) => v.name)).length > 0) {
      return openNotification("Error", `There are duplicate value.`)
    }

    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return
          openNotification("Success", `New product option "${formValue.name}" has been created.`)
          setUploadPercentage([])
          form.resetFields()
        },
      }
    )
  }

  const cancelEditHandle = () => {
    handleEdit(null)
    form.resetFields()
  }

  const updateHandle = (formValue) => {
    if (toFindDuplicates(formValue.variations.map((v) => v.name)).length > 0) {
      return openNotification("Error", `There are duplicate value.`)
    }
    mutation.mutate(
      {
        id: editId,
        data: formValue,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)
          openNotification("Success", `Product option "${formValue.name}" has been updated.`)
          handleEdit(null)
          setUploadPercentage([])
          form.resetFields()
        },
      }
    )
  }

  useEffect(() => {
    if (editId) {
      const [data] = queryClient.getQueryData("product-options").filter((a) => a._id === editId)

      form.setFieldsValue({
        name: data.name,
        variations: data.variations,
      })

      setEditedData(data)
      return
    }
    form.resetFields()
    setEditedData(null)
  }, [editId])

  return (
    <>
      <h1 className="font-bold text-xl">Add Option</h1>

      <Form layout="vertical" name="basic" size="large" form={form} onFinish={editId ? updateHandle : submitHandle}>
        <Form.Item label="Option Name" name="name" rules={[{ required: true, message: "Please input option name!" }]}>
          <Input />
        </Form.Item>

        <Form.List name="variations">
          {(fields, { add, remove }) => (
            <>
              <label className="block mb-4 text-black">Variations</label>
              <div className="flex flex-wrap relative -mx-4">
                {fields.map((field) => (
                  <div key={field.key} className="w-1/2 px-4 flex flex-wrap option-outer relative pr-7">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights}
                    >
                      {() => (
                        <Form.Item
                          {...field}
                          label="Name"
                          name={[field.name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </Form.Item>

                    <Form.Item {...field} key="colorCode" label="Color Code" name={[field.name, "colorCode"]}>
                      <Input placeholder="optional" />
                    </Form.Item>

                    <MinusCircleOutlined className="absolute right-3 top-9 text-xl text-red-500" onClick={() => remove(field.name)} />
                  </div>
                ))}
              </div>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Variation
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}
        <Form.Item>
          <div className="flex space-x-4">
            {!uploadPercentage.find((u) => u === 0) && (
              <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
                {editId ? "Update" : "Crate"}
              </Button>
            )}
            {editId && (
              <Button type="primary" className="button px-10" danger onClick={cancelEditHandle}>
                Cancel Edit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
