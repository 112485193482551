import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Alert, Button, Col, Form, Input, Row, Select, Modal, Image, InputNumber, Spin, Switch, Tag } from "antd"
import { useQueryClient } from "react-query"
import React, { useState, useEffect, useRef } from "react"
import { useReactQueryMutation } from "../../../hooks/useMutation"
import { UseData } from "../../../hooks/useReactQuery"
import { openNotification } from "../../../utils/notification"
import FileUpload from "../../FileUpload"
import API from "../../../helper/api"
import { getImagePath } from "../../../utils/uri"
import RefetchButton from "../../RefetchButton"

const UpcSection = ({ setOpen, selectedProduct }) => {
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [product, setProduct] = useState(null)
  const upcRef = useRef()
  const queryClient = useQueryClient()
  const handleFindUpc = async () => {
    const value = upcRef.current.input.value
    if (value === "") return
    setLoading(true)
    const [res, error] = await new API().get(`product/upc/${String(value)}`)
    setLoading(false)
    if (error) {
      openNotification("Warning", error.message)
      return
    }
    setIsModalOpen(true)
    setProduct(res.data)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleConfirm = async () => {
    if (!product) {
      setIsModalOpen(false)
      return
    }

    setLoading(true)

    const [res, error] = await new API({ product: product._id }).update(`buy-order/product/${selectedProduct._id}`)
    setLoading(false)
    if (error) {
      openNotification("Warning", error.message)
      return
    }
    openNotification("Success", `The buy order has been successfully updated`)
    await queryClient.invalidateQueries(["pending-products"])

    setOpen(false)
  }
  return (
    <div className="relative pr-32 mb-5">
      <Form.Item label="UPC" name="upc" rules={[{ required: true, message: "Please input UPC!" }, { type: "string" }]}>
        <Input ref={upcRef} size="large" />
      </Form.Item>
      <Button type="primary" className="absolute right-0 top-11" onClick={handleFindUpc} loading={loading}>
        Find UPC
      </Button>

      <Modal
        title={"Confirm Buy request"}
        open={isModalOpen}
        onOk={handleConfirm}
        okText="Confirm"
        okButtonProps={{ loading: loading }}
        onCancel={handleCancel}
      >
        <h3 className="capitalize pt-5 mb-3">Base Product: {product?.baseProduct}</h3>

        {product?.image && <Image width={300} src={getImagePath(product?.image)} />}
        <h3 className="capitalize mb-3">Option: {product?.option?.name || "-"}</h3>
        {product?.variations && (
          <ul className="p-0 space-y-3">
            {product?.variations.map((v, i) => (
              <li key={`v-s-${i}`} className="flex space-x-2">
                <strong>{v.name}:</strong>
                <span>{v.item.name}</span>
              </li>
            ))}
          </ul>
        )}
      </Modal>
    </div>
  )
}

const VariationValue = ({ selectedProduct, handleChange, field }) => {
  const variationItem = selectedProduct.variationName

  return (
    <Form.Item
      name={[field.name, "value"]}
      label="Variation Value"
      rules={[
        {
          required: true,
          message: "Please select variation value",
        },
      ]}
    >
      <Select
        placeholder="Select a variation"
        allowClear
        showSearch
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        onChange={(d) => handleChange("variationValue", d)}
      >
        {variationItem?.items?.map((d) => (
          <Select.Option value={JSON.stringify(d)} key={d.name}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

const VariationItem = ({ field, remove, selectedProduct, form, handleChange }) => {
  const { option, baseProduct } = selectedProduct
  const [variationName, setVariationName] = useState(false)
  const handleOneChange = (e) => {
    setVariationName(true)
    handleChange("variationName", e)
  }
  const variations = baseProduct.hasOption ? option?.variations : baseProduct.variations

  return (
    <div className="relative pr-14 flex space-x-4">
      <div className="w-1/2">
        <Form.Item
          {...field}
          label="Select Variation name"
          name={[field.name, "name"]}
          key={`value-${field.key}`}
          rules={[
            {
              required: true,
              message: "Missing value name",
            },
          ]}
        >
          <Select
            placeholder="Select value"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onChange={handleOneChange}
          >
            {variations?.map((v) => (
              <Select.Option value={JSON.stringify(v)} key={v.name}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {variationName && (
        <div className="w-1/2">
          <VariationValue selectedProduct={selectedProduct} variationName={variationName} field={field} handleChange={handleChange} />
        </div>
      )}
      <MinusCircleOutlined className="absolute right-3 top-14 text-xl text-red-500" onClick={() => remove(field.name)} />
    </div>
  )
}

const OptionSelect = ({ selectedProduct, handleChange }) => {
  const { baseProduct } = selectedProduct

  if (!baseProduct) return null

  const { data, isLoading, isError, error, refetch, isRefetching } = UseData(`option-${baseProduct?._id}`, `base-product/option/${baseProduct?._id}`)

  if (isError) return <div>{error.message}</div>

  if (!isLoading && data.length === 0) return <h3 className="text-orange-300">No option found please create option if needed</h3>
  return (
    <div className="relative pr-14">
      <Form.Item name="option" label="Option Name" className="relative">
        <Select
          placeholder="Select a option"
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          onChange={(m) => handleChange("option", m)}
          loading={isLoading}
        >
          {data?.map((d) => (
            <Select.Option value={JSON.stringify(d)} key={d.name}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <RefetchButton refetch={refetch} name="Option" loading={isLoading || isRefetching} />
    </div>
  )
}

const BaseProductSelect = ({ handleChange }) => {
  const { data, isLoading, isError, refetch, isRefetching } = UseData("base-products", `base-product`)
  if (isError) return null
  return (
    <div className="pr-14 relative">
      {isLoading && (
        <div className="absolute inset-0 h-full bg-white/70 z-20 flex items-center justify-center">
          <Spin />
        </div>
      )}
      <Form.Item
        name="baseProduct"
        label="Base Product"
        rules={[
          {
            required: true,
            message: "Please select Base Product",
          },
        ]}
      >
        <Select
          placeholder="Select a base product"
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          onChange={(data) => handleChange("baseProduct", data)}
          loading={isLoading}
        >
          {data.map((d) => (
            <Select.Option value={JSON.stringify(d)} key={d._id}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <RefetchButton refetch={refetch} name="Base Product" loading={isLoading || isRefetching} />
    </div>
  )
}

export default function PendingRequestForm({ setOpen, selectedProduct, setSelectedProduct }) {
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(false)
  const [success, setSuccess] = useState(false)
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (Object.keys(file).length === 0) return setFileSet(false)
  }, [file])

  useEffect(() => {
    if (!selectedProduct?.baseProduct) return

    form.setFieldsValue({
      baseProduct: selectedProduct?.baseProduct.name,
    })
  }, [])

  useEffect(() => {
    let title = ""
    if (selectedProduct?.baseProduct?.brand) {
      title += selectedProduct?.baseProduct?.brand.name + " - " + selectedProduct.baseProduct.name
    }
    if (selectedProduct?.option) {
      title += " - " + selectedProduct?.option?.name
    }
    if (selectedProduct.variations) {
      selectedProduct.variations.forEach((v) => {
        title += " - " + v.item.name
      })
    }
    form.setFieldsValue({
      name: title,
    })
  }, [selectedProduct])

  const handleChange = (key, data) => {
    if (key === "baseProduct") {
      form.setFieldsValue({
        variations: "",
        option: "",
      })
      if (data) {
        const parsedData = JSON.parse(data)
        setSelectedProduct((prev) => ({ ...prev, [key]: parsedData }))
      }
      return
    }
    if (key === "option") {
      form.setFieldsValue({
        variations: "",
      })
      if (data) {
        const parsedData = JSON.parse(data)
        setSelectedProduct((prev) => ({ ...prev, [key]: parsedData }))
      }

      return
    }
    if (key === "variationName") {
      const parsedData = JSON.parse(data)
      setSelectedProduct((prev) => ({ ...prev, [key]: parsedData }))
      return
    }
    if (key === "variationValue") {
      const parsedData = JSON.parse(data)

      if (selectedProduct.variationValue) {
        return setSelectedProduct((prev) => ({ ...prev, variationValue: [...prev.variationValue, parsedData.name] }))
      }
      return setSelectedProduct((prev) => ({ ...prev, variationValue: [parsedData.name] }))
    }
  }

  const mutation = useReactQueryMutation("active-products", "product/approve", file)
  // const { data, isLoading } = UseData("retailers", "retailer")

  const setFileHandle = (e) => {
    setFileSet(false)
    setFile(e)
  }

  const submitHandle = async (formValue) => {
    const { baseProduct, option, variations, name, description, upc, retailPrice, ...rest } = formValue

    const parsedBaseProduct = selectedProduct.baseProduct

    const sanitizedOjb = {
      baseProduct: parsedBaseProduct.slug,
      buyOrderId: selectedProduct._id,
      name: name,
      description: description,
      upc: upc,
      retailPrice: retailPrice,
    }

    // if model exist
    if (selectedProduct.hasOption) {
      // const parsedOption = JSON.parse(option)
      // sanitizedOjb["option"] = {
      //   name: parsedOption.name,
      //   nameId: parsedOption.nameId,
      //   optionKeyName: parsedOption.optionKeyName,
      //   optionKeyId: parsedOption.optionKeyId,
      // }
      sanitizedOjb["option"] = selectedProduct.option
    }

    // // parse all the variations
    if (selectedProduct.hasVariations) {
      // const parsedVariations = variations.map((v) => {
      //   const name = JSON.parse(v.name)
      //   const value = JSON.parse(v.value)

      //   return {
      //     name: name.name,
      //     nameId: name.nameId,
      //     item: {
      //       colorCode: value?.colorCode,
      //       nameId: value.nameId,
      //       name: value.name,
      //     },
      //   }
      // })

      sanitizedOjb["variations"] = selectedProduct.variations
    }

    if (rest.useImageToBaseProduct && sanitizedOjb.variations.length > 1) {
      return openNotification("Error", "This Product only allows single variation.")
    }

    setSuccess(false)
    if (Object.keys(file).length === 0) {
      setFileSet(true)
      return
    }

    mutation.mutate(
      {
        method: "UPDATE",
        id: selectedProduct._id,
        data: sanitizedOjb,
      },
      {
        onSuccess: async (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)

          if (Object.keys(file).length > 0) {
            setSuccess(true)
          }

          await queryClient.invalidateQueries(["pending-products"])

          openNotification("Success", `Product "${formValue.name}" has been successfully approved.`)
          form.resetFields()
          setFile({})
          setTimeout(() => {
            setOpen(false)
          }, 500)
        },
      }
    )
  }

  return (
    <Form layout="vertical" size="large" autoComplete="off" className="w-2/3" form={form} onFinish={submitHandle}>
      <UpcSection setOpen={setOpen} selectedProduct={selectedProduct} />
      {/* <BaseProductSelect handleChange={handleChange} /> */}
      <Form.Item label="Base Product" name="baseProduct" rules={[{ required: true, message: "Please input base product!" }, { type: "string" }]}>
        <Input />
      </Form.Item>

      <div className="">
        <h4>Base Product</h4>
        <strong>{selectedProduct.baseProduct.name}</strong>
      </div>

      {selectedProduct && (
        <div className="">
          {selectedProduct.hasOption && (
            <div className="">
              <h3>Options</h3>
              name: <Tag color="blue">{selectedProduct.option.name}</Tag>
            </div>
          )}
          {selectedProduct.hasVariations && (
            <div className="mt-5">
              <h3>Variations</h3>
              {selectedProduct.variations.map((v) => (
                <div key={`variation-${v._id}`} className="flex mb-3">
                  <span>{v?.name}: </span> <Tag color="blue">{v.item.name}</Tag>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* {selectedProduct.baseProduct.hasOption && (
        <>
          <OptionSelect selectedProduct={selectedProduct} handleChange={handleChange} />
          {selectedProduct.option && (
            <Form.List name="variations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, i) => (
                    <VariationItem
                      handleChange={handleChange}
                      key={`variation-${i}`}
                      field={field}
                      remove={remove}
                      selectedProduct={selectedProduct}
                      form={form}
                    />
                  ))}

                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Variation
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </>
      )}

      {selectedProduct.baseProduct.hasVariation && !selectedProduct.baseProduct.hasOption ? (
        <>
          <Form.List name="variations">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, i) => (
                  <VariationItem
                    handleChange={handleChange}
                    key={`variation-${i}`}
                    field={field}
                    remove={remove}
                    selectedProduct={selectedProduct}
                    form={form}
                  />
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Variation
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </>
      ) : null} */}

      <Form.Item label="Product Name" name="name" rules={[{ required: true, message: "Please input your product name!" }, { type: "string" }]}>
        <Input size="large" />
      </Form.Item>

      <Row gutter={10}>
        <Col span={24}>
          <Form.Item label="Retail Price" name="retailPrice" rules={[{ required: true, message: "Please input retail price." }, { type: "number" }]}>
            <InputNumber size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      {selectedProduct.baseProduct.hasVariation && !selectedProduct.baseProduct.hasOption ? (
        <Form.Item name="useImageToBaseProduct" label="Use this image to base Product" valuePropName="checked">
          <Switch />
        </Form.Item>
      ) : null}

      <FileUpload setFileHandle={setFileHandle} success={success} />

      <Form.Item label="TextArea" name="description" rules={[{ required: true, message: "Product description is required." }]}>
        <Input.TextArea rows={4} />
      </Form.Item>

      {fileSet && (
        <div className="mb-5">
          <Alert message="Please select the image!" type="error" showIcon />
        </div>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={mutation.isLoading}>
          Create and approve
        </Button>
      </Form.Item>
    </Form>
  )
}
