import { DeleteOutlined, EditOutlined, BorderInnerOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Table, Tooltip } from "antd"
import { useState } from "react"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import { getImagePath } from "../../utils/uri"

export default function BaseProductTable({ handleEdit }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { data, isLoading } = UseData("base-products", "base-product")
  const mutation = useReactQueryMutation("base-products", "base-product")

  const deleteHandel = (id, name) => {
    setConfirmLoading(true)
    mutation.mutate(
      {
        id: id,
        method: "DELETE",
      },
      {
        onSuccess: (e) => {
          openNotification("success", `Brand "${name}" has been deleted.`)
          setVisible(false)
          setConfirmLoading(false)
        },
      }
    )
  }
  const showPopConfirm = (id) => {
    setVisible(id)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      responsive: ["xl"],
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => text,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text) => text,
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      render: (text) => text,
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      responsive: ["xl"],
      render: (images) => (
        <>
          {images && (
            <div className="w-12 border border-gray-300 p-0.5 rounded-sm block">
              <img src={getImagePath(images.medium.name)} alt="" />
            </div>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Button size="medium" type="primary" shape="circle" icon={<EditOutlined />} onClick={() => handleEdit(record._id)} />
          <Popconfirm
            title={`Are sure you want to delete this "${record.name}" brand?`}
            open={record.key === visible}
            onConfirm={() => deleteHandel(record._id, record.name)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={() => setVisible(false)}
          >
            <Button size="medium" type="primary" danger shape="circle" onClick={() => showPopConfirm(record.key)} icon={<DeleteOutlined />} />
          </Popconfirm>
          <Tooltip title="Add product options">
            <Button size="medium" type="primary" shape="circle" icon={<BorderInnerOutlined />} />
          </Tooltip>
        </div>
      ),
    },
  ]

  if (data.length === 0 && !isLoading) return null
  const tableData = data.map((d) => ({ key: d._id, ...d }))

  return (
    <div className="pt-5 border-t border-gray-300 mt-8">
      <h1 className="font-bold text-xl pb-3">All Base Product</h1>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoading}
        pagination={{ defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ["10", "20", "30"] }}
      />
    </div>
  )
}
