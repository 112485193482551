import React from "react"
import { Button, Form, Select } from "antd"
import { UseData } from "../../../hooks/useReactQuery"
import { openNotification } from "../../../utils/notification"
import { useReactQueryMutation } from "../../../hooks/useMutation"
import { useQueryClient } from "react-query"

export default function SelectProduct({ setOpen, selectedProduct }) {
  const { data, isLoading, isError, error } = UseData("products", "product")

  const mutation = useReactQueryMutation("custom-sell-request", `sell-offer/product`)
  const queryClient = useQueryClient()

  const [form] = Form.useForm()

  if (isError) return <h1>{error.message}</h1>

  const submitHandle = async (formValue) => {
    const selectedItem = data?.find((d) => d?._id === formValue?.product)

    mutation.mutate(
      {
        method: "UPDATE",
        id: selectedProduct._id,
        data: {
          product: formValue.product,
          upc: selectedItem?.upc,
        },
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") {
            openNotification("Error", e.message)
            return
          }
          queryClient.setQueryData("custom-sell-request", (old) => old.filter((a) => a._id !== selectedProduct._id))

          openNotification("Success", `The Order has been successfully assign to product.`)
          form.resetFields()

          setTimeout(() => {
            setOpen(false)
          }, 500)
        },
      }
    )
  }

  return (
    <div>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Form.Item
          name="product"
          label="Select Product"
          rules={[
            {
              required: true,
              message: "Please select Product",
            },
          ]}
        >
          <Select
            placeholder="Select a product"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            loading={isLoading}
          >
            {data?.map((d) => (
              <Select.Option value={d._id} key={d._id} className="capitalize">
                {d.name || d.slug}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={mutation.isLoading}>
          Confirm
        </Button>
      </Form>
    </div>
  )
}
