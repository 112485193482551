import React from "react"
import Wrapper from "../../components/Wrapper"
import Dispute from "../../components/dispute"

export default function DisputePending() {
  return (
    <Wrapper>
      <Dispute status="pending approval" name="pending-dispute" />
    </Wrapper>
  )
}
