import React, { useState } from "react"
import Wrapper from "../../components/Wrapper"
import OfferRequestTable from "../../components/product/request/OfferRequestTable"
import DrawerCustom from "../../components/product/request/DrawerCustom"
export default function OfferRequest() {
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const handleSelect = (sellOffer) => {
    setSelectedProduct(sellOffer)
    setOpen(true)
  }
  return (
    <Wrapper>
      <OfferRequestTable handleSelect={handleSelect} />
      <DrawerCustom setOpen={setOpen} open={open} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
    </Wrapper>
  )
}
