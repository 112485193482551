import { Col, Form, Row, Select, Alert } from "antd"
import { useEffect, useState } from "react"
import { UseData } from "../../../hooks/useReactQuery"
import RefetchButton from "../../RefetchButton"

const BaseProductSelect = ({ selectedOption, setSelectedOption, handleOnChange }) => {
  const { category, productType, brand } = selectedOption

  const queryString = String(new URLSearchParams({ category, productType, brand }))

  const { data, isLoading, isError, error, refetch, isRefetching } = UseData(queryString, `base-product?${queryString}`)

  const handleChange = (key, id) => {
    setSelectedOption((prev) => ({ ...prev, baseProduct: data.find((d) => d._id === id) }))
  }

  if (isError) {
    return <Alert message={error?.status} description={error?.message} type="error" closable onClose={() => console("closed")} />
  }
  return (
    <Col span={12}>
      <div className="pr-10 relative">
        <Form.Item
          name="baseProduct"
          label="Base Product"
          rules={[
            {
              required: true,
              message: "Please select Base Product",
            },
          ]}
        >
          <Select
            placeholder="Select a base product"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            loading={isLoading}
            onSelect={(id) => handleChange("baseProduct", id)}
          >
            {data.map((d) => (
              <Select.Option value={d._id} key={d._id}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <RefetchButton name="Base Product" loading={isLoading || isRefetching} refetch={refetch} />
      </div>
    </Col>
  )
}

const SelectOption = ({ handleOnChange, name, path, label, queryKey }) => {
  const { data, isLoading, isError, refetch, isRefetching } = UseData(queryKey, path)

  if (isError) return null
  return (
    <Col span={8}>
      <div className="relative pr-10">
        <RefetchButton refetch={refetch} loading={isLoading || isRefetching} name={label} />

        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              message: `Please select ${label}.`,
            },
          ]}
        >
          <Select
            placeholder="Select a product type"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            onSelect={(slug) => handleOnChange(name, slug)}
            loading={isLoading || isRefetching}
          >
            {data.map((d) => (
              <Select.Option value={d.slug} key={d.slug}>
                {d.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </Col>
  )
}

export default function FindBaseProduct({ model, setSelectedOption, selectedOption }) {
  const [form] = Form.useForm()

  const category = UseData("category", "category")

  const handleOnChange = (key, slug) => {
    setSelectedOption((prev) => ({ ...prev, [key]: slug }))
    if (key === "productType") {
      form.setFieldsValue({
        brand: "",
        baseProduct: "",
      })
      if (selectedOption["brand"]) setSelectedOption((prev) => ({ productType: slug, category: prev.category }))
    }
    if (key === "brand") {
      form.setFieldsValue({
        baseProduct: "",
      })
      if (selectedOption["baseProduct"]) setSelectedOption((prev) => ({ productType: prev.productType, brand: slug, category: prev.category }))
    }
  }
  useEffect(() => {
    if (!category.isLoading) {
      setSelectedOption((prev) => ({ ...prev, category: category?.data[0]?.slug }))
    }

    form.resetFields()
  }, [category.isLoading])
  return (
    <>
      <h1 className="font-bold text-xl">Find Base Product</h1>

      <Form layout="vertical" name="basic" size="large" form={form}>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="category"
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
              initialValue={category?.data[0]?.slug}
            >
              <Select
                placeholder="Select a category"
                allowClear
                showSearch
                //filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                disabled
                loading={category.isLoading}
              >
                {category.data.map((d) => (
                  <Select.Option value={d.slug} key={d.slug}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <SelectOption
            handleOnChange={handleOnChange}
            label="Product Type"
            name={"productType"}
            queryKey={`product-${selectedOption.category}`}
            path="base-product/product-type"
          />
          {selectedOption["productType"] && (
            <SelectOption
              handleOnChange={handleOnChange}
              label="Brand"
              name="brand"
              queryKey={`brand-${selectedOption.productType}`}
              path={`base-product/brand?productType=${selectedOption.productType}`}
            />
          )}
          {Object.keys(selectedOption).length > 2 && (
            <BaseProductSelect selectedOption={selectedOption} setSelectedOption={setSelectedOption} handleOnChange={handleOnChange} />
          )}
        </Row>
      </Form>
    </>
  )
}
