import React from "react";
import Wrapper from "../components/Wrapper";
import Brand from "../components/brand";
export default function BrandPage() {
  return (
    <Wrapper>
      <Brand />
    </Wrapper>
  );
}
