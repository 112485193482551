import React, { useState, useEffect, useRef } from "react"
import { Button, Form, Input, Typography, Alert } from "antd"
import ReCAPTCHA from "react-google-recaptcha"
import API from "../helper/api"
import { getCookie } from "../utils/getCookies"

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const recaptchaRef = useRef()

  const csrfToken = getCookie("csrf-token")
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://www.google.com/recaptcha/api.js?render=6LcYykApAAAAAPthH3uJNuIhBrkpAMiS7VVV42Pk"
    script.async = true

    document.body.appendChild(script)
  }, [])

  const onFinish = async (values) => {
    setError(null)
    if (!recaptchaRef?.current) {
      return setError({ message: "Invalid captcha." })
    }

    const captcha = await recaptchaRef.current.getValue()

    if (!captcha && process.env.NODE_ENV !== "development") {
      return setError({ message: "Invalid captcha." })
    }
    setLoading(true)

    const [user, err] = await new API(
      {
        email: values.email,
        password: values.password,
        captcha,
      },
      {
        "csrf-token": csrfToken,
      }
    ).auth("/login")
    recaptchaRef.current.reset()

    setLoading(false)

    //if has error
    if (err) {
      setError(err)
      return
    }
    if (user?.data?.role !== "admin") {
      setError({
        message: "you do not have the access.",
      })
      return
    }
    window.location.reload()
  }

  return (
    <div className="container py-10">
      <Typography.Title className="text-center">Login</Typography.Title>
      <div className="max-w-xl mx-auto border border-gray-200 shadow-lg bg-white rounded-lg p-12">
        <Form name="basic" initialValues={{ remember: true }} size="large" onFinish={onFinish} autoComplete="off" layout="vertical">
          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email address!" }]}>
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
            <Input.Password />
          </Form.Item>
          {error && (
            <div className="mb-5">
              <Alert message="Error" description={error.message} type="error" showIcon />
            </div>
          )}
          <div className="mb-3">
            <ReCAPTCHA ref={recaptchaRef} size="normal" sitekey="6LcYykApAAAAAPthH3uJNuIhBrkpAMiS7VVV42Pk" grecaptcha={window.grecaptcha} />
          </div>

          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Login
