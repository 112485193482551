import {
  AppstoreOutlined,
  BorderBottomOutlined,
  BorderInnerOutlined,
  DashboardOutlined,
  DesktopOutlined,
  ProjectOutlined,
  SettingOutlined,
  ShopOutlined,
  LogoutOutlined,
  WarningOutlined,
} from "@ant-design/icons"

import { Button, Layout, Menu } from "antd"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import API from "../helper/api"
const { Sider } = Layout

function getItem(label, key, path, icon, children) {
  return {
    key,
    icon,
    children,
    label: <Link to={path}>{label}</Link>,
  }
}

const items = [
  getItem("Dashboard", "dashboard", "/dashboard", <DashboardOutlined />),
  getItem("Category", "category", "/category", <AppstoreOutlined />),
  getItem("Product Type", "product-type", "/product-type", <AppstoreOutlined />),
  getItem("Brand", "brand", "/brand", <DesktopOutlined />),
  getItem("Product Options", "options", "/product-options", <BorderInnerOutlined />),
  getItem("Base Product", "base-product", "/base-product/all", <ProjectOutlined />, [
    getItem("All Base Product", "base-product-all", "/base-product/all"),
    getItem("Add Options", "base-product-model-add", "/base-product/add-option"),
    getItem("Delete option", "base-product-model-delete", "/base-product/delete-option"),
  ]),
  getItem("Product", "products", "/product/active", <ShopOutlined />, [
    getItem("Active Products", "active-products", "/product/active"),
    getItem("Pending Products", "pending-products", "/product/pending"),
    getItem("Add Product", "add-product", "/product/add"),
    getItem("Custom Request", "request", "/product/request"),
    getItem("Listing Request", "offer-request", "/product/offer-request"),
  ]),
  getItem("Retailer", "retailer", "/retailer", <BorderBottomOutlined />),
  getItem("Setting", "setting", "/setting/user-role", <SettingOutlined />, [
    getItem("User Role", "user-role", "/setting/user-role"),
    getItem("User Balance", "user-balance", "/setting/user-balance"),
    getItem("Announcement", "announcement", "/setting/announcement"),
    getItem("Withdrawal", "withdrawal", "/setting/withdrawal"),
    getItem("Deposit", "deposit", "/setting/deposit"),
  ]),
  getItem("Disputes", "disputes", "/disputes/pending", <WarningOutlined />, [
    getItem("Pending", "pending", "/disputes/pending"),
    getItem("Escalate", "escalate", "/disputes/escalate"),
    getItem("Expired", "expired", "/disputes/expired"),
  ]),
]

const Sidebar = () => {
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  useEffect(() => {
    setActive(history.location.pathname.split("/")[1])
  }, [history])
  const handleOnSelect = (e) => {
    setActive(e.key)
  }
  const handleLogout = async () => {
    setLoading(true)
    const [res, err] = await new API().get("user/logout")
    setLoading(false)
    if (err || res.status === "fail") return
    window.location.href = "/"
  }
  return (
    <div className="fixed top-0 bottom-0 left-0 bg-[#061629] z-20 max-h-full overflow-auto">
      <Sider>
        <div className="text-base font-bold text-white px-5 pt-5 pb-5 text-center">Trade Admin Panel</div>
        <Menu theme="dark" mode="inline" items={items} defaultSelectedKeys={["2"]} defaultOpenKeys={[active]} onSelect={handleOnSelect} />
        {/* <div className="p-5 absolute bottom-10 left-0">
          <Button loading={loading} onClick={handleLogout}>
            <LogoutOutlined />
            Logout
          </Button>
        </div> */}
      </Sider>
    </div>
  )
}

export default Sidebar
