import React, { useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { Button, Popover, Input, Form } from "antd"
import API from "../../../helper/api"
import { openNotification } from "../../../utils/notification"

export default function EscalateButtons({ disputeItem, reason, onClose }) {
  const [loading, setLoading] = useState(false)
  const [declinedLoading, setDeclineReason] = useState(false)
  const queryClient = useQueryClient()

  const handleConfirm = async (winner) => {
    setLoading(true)
    const [res, err] = await new API({
      reasonId: reason._id,
      winner,
    }).update(`dispute/admin/package/close/${disputeItem._id}`)

    setLoading(false)

    if (res?.status === "fail" || err) {
      return openNotification("Error", err ? err.message : res.message)
    }
    openNotification("Success", `${winner} won the dispute`)
    await Promise.all([queryClient.invalidateQueries(["escalated-dispute"]), queryClient.invalidateQueries(["expire-dispute"])])
    onClose()
  }

  return (
    <>
      <Popover
        content={
          <div className="w-72">
            <p>Are you sure you want to approve buyer</p>
            <Button type="primary" size="large" onClick={() => handleConfirm("buyer")} loading={declinedLoading}>
              Submit
            </Button>
          </div>
        }
        trigger="click"
        title="Buyer confirmation"
      >
        <Button type="primary" size="large" disabled={loading || declinedLoading} loading={declinedLoading}>
          Buyer
        </Button>
      </Popover>
      <Popover
        content={
          <div className="w-72">
            <p>Are you sure you want to approve for both.</p>
            <Button type="primary" size="large" onClick={() => handleConfirm("both")} loading={loading}>
              Yes
            </Button>
          </div>
        }
        trigger="click"
        title="confirmation for both"
      >
        <Button size="large" disabled={loading || declinedLoading}>
          Middle
        </Button>
      </Popover>
      <Popover
        content={
          <div className="w-72">
            <p>Are you sure you want to approve seller</p>
            <Button type="primary" size="large" onClick={() => handleConfirm("seller")} loading={loading}>
              Yes
            </Button>
          </div>
        }
        trigger="click"
        title="Seller confirmation"
      >
        <Button type="primary" size="large" disabled={loading || declinedLoading}>
          Seller
        </Button>
      </Popover>
    </>
  )
}
