import { useState } from "react"
import BrandForm from "./BrandForm"
import BrandTable from "./BrandTable"

export default function Brand() {
  const [editId, setEditId] = useState(null)
  const handleEdit = (id) => {
    setEditId(id)
  }
  return (
    <>
      <BrandForm editId={editId} handleEdit={handleEdit} />
      <BrandTable handleEdit={handleEdit} />
    </>
  )
}
