import { useState } from "react"
import RetailerFrom from "./RetailerForm"
import RetailerTable from "./RetailerTable"

export default function Retailer() {
  const [editId, setEditId] = useState(null)
  const handleEdit = (id) => {
    setEditId(id)
  }
  return (
    <>
      <RetailerFrom editId={editId} handleEdit={handleEdit} />
      <RetailerTable handleEdit={handleEdit} />
    </>
  )
}
