import { useQuery, useQueryClient, useInfiniteQuery } from "react-query"
import API from "../helper/api"

export const UseData = (key, path) => {
  const { isLoading, error, data = [], status, refetch, isError, isRefetching } = useQuery(key, async () => await new API().fetchData(path))
  return {
    isLoading,
    error,
    data,
    status,
    refetch,
    isError,
    isRefetching,
  }
}

export const LoadMore = async (name, apiURL, page) => {
  const queryClient = useQueryClient()
  const res = await new API().fetchDataAll(`${apiURL}&limit=${30}&page=${page}`)
  queryClient.setQueryData(name, (prevState) => [...prevState, ...res.data])
  return res.pages
}

export const useInfinityFetchQuery = (queryKey, path, statusQuery) => {
  const fetchData = async ({ pageParam = 1 }) => {
    const data = await new API().fetchDataAll(statusQuery ? `${path}?page=${pageParam}&status=${statusQuery}` : `${path}?page=${pageParam}`)

    if (data.status === "fail") {
      throw new Error(data.message)
    }
    data.nextPage = pageParam
    return data
  }

  return useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: fetchData,
    onError: (error) => error,
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => (!lastPage?.hasNextPage ? undefined : !lastPage?.nextPage ? 2 : lastPage?.nextPage + 1),
  })
}
