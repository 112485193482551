import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import API from "../helper/api"

export const useReactQueryMutation = (name, path, file) => {
  const [loading, setLoading] = useState(false)
  const [err, setError] = useState(null)
  const queryClient = useQueryClient()

  const { _, isError, error, mutate, status } = useMutation(
    async (variables) => {
      setLoading(true)
      if (variables.method === "DELETE") {
        return await new API(variables.data).deleteData(`${path}/${variables.id}`)
      }
      if (variables.method === "UPDATE") {
        return await new API(variables.data).postData(file, `${path}/${variables.id}`, "PATCH")
      }
      return await new API(variables.data).postData(file, path)
    },
    {
      onMutate: async (variables) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(name)

        // Snapshot the previous value
        const previousData = queryClient.getQueryData(name)

        // Return a context object with the snapshotted value
        return { previousData }
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        setError(err.response.data.message)
        setLoading(false)
        if (variables.mthod === "UPDATE") {
          queryClient.setQueryData([name, context.variables.id], context.previousTodo)
          return
        }
        queryClient.setQueryData(name, context.previousData)
      },
      // Always refetch after error or success:
      onSettled: (res, error, variables) => {
        if (res?.status === "fail" || res?.status === "error") {
          setError(res.message)
          setLoading(false)
          return res
        }

        if (variables.method === "CREATE") {
          queryClient.setQueryData(name, (old) => {
            if (old) {
              return [res.data, ...old]
            }
            return [res.data]
          })
          queryClient.invalidateQueries(name)
        }
        if (variables.method === "UPDATE") {
          queryClient.setQueryData([name, { id: variables.id }], res.data)
          queryClient.invalidateQueries(name)
        }
        if (variables.method === "DELETE") {
          queryClient.invalidateQueries(name)
          //queryClient.setQueryData([name], (old) => ({ ...old, data: old.data.filter((a) => a._id !== variables.id) }))
        }
        setLoading(false)
        if (!error) {
          setError(null)
        }
      },
    }
  )
  return {
    isLoading: loading,
    isError,
    error: err,
    status,
    mutate,
  }
}
