import React from "react"
import Wrapper from "../../components/Wrapper"
import ProductOption from "../../components/productOption"
export default function ProductOptionPage() {
  return (
    <Wrapper>
      <ProductOption />
    </Wrapper>
  )
}
