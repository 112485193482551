import React, { useState } from "react"
import Wrapper from "../../components/Wrapper"
import CustomRequestTable from "../../components/product/request/CustomRequestTable"
import DrawerCustom from "../../components/product/request/DrawerCustom"
export default function RequestPage() {
  const [open, setOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const handleSelect = (buyOrder) => {
    setSelectedProduct({ buyOrder: buyOrder, baseProduct: buyOrder.customDetails.baseProduct })
    setOpen(true)
  }
  return (
    <Wrapper>
      <CustomRequestTable handleSelect={handleSelect} />
      <DrawerCustom setOpen={setOpen} open={open} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
    </Wrapper>
  )
}
