import React, { useRef, useState } from "react"
import { Space, Table, Tag, Button, Popover, Input } from "antd"
import { UseData } from "../../../hooks/useReactQuery"
import { openNotification } from "../../../utils/notification"
import { useReactQueryMutation } from "../../../hooks/useMutation"
import { useQueryClient } from "react-query"

const CustomRequest = ({ handleSelect }) => {
  const [loading, setLoading] = useState(false)
  const { data, isLoading, isError, refetch, error, isRefetching } = UseData("custom-request", "buy-order/custom-request")
  const declineInputRef = useRef()
  const queryClient = useQueryClient()

  const mutation = useReactQueryMutation("custom-request", `buy-order/declined`)

  if (isError) return error.message

  const tableData = data?.map((d) => ({ key: d._id, ...d }))

  const declinedReason = (
    <div className="w-96">
      <Space direction="vertical" style={{ display: "flex" }}>
        <Input.TextArea rows={4} ref={declineInputRef} maxLength={200} showCount={true} />
      </Space>
    </div>
  )

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value, record) => {
        const {
          customDetails: { title, baseProduct },
        } = record
        if (title) return title
        return baseProduct.name || "-"
      },
    },
    {
      title: "UPC",
      dataIndex: "upc",
      key: "upc",
      render: (value, record) => <Tag color="green">{record?.customDetails?.upc || "-"}</Tag>,
    },
    {
      title: "Product Type",
      dataIndex: "product-type",
      key: "productType",
      render: (value, record) => <Tag color="blue">{record?.customDetails?.productType || "-"}</Tag>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (value, record) => <Tag color="blue">{record?.customDetails?.brand || "-"}</Tag>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleSelect(record)}>
            Approve
          </Button>
          <Popover
            content={
              <div>
                {declinedReason}
                <Button danger className="mt-4" onClick={() => handleDecline(record._id)} loading={record._id === loading}>
                  Decline
                </Button>
              </div>
            }
            title="Decline reason"
            trigger="click"
          >
            <Button danger loading={record._id === loading}>
              Decline
            </Button>
          </Popover>
        </Space>
      ),
    },
  ]

  const handleDecline = async (id) => {
    let reason = declineInputRef.current.resizableTextArea.textArea.value
    if (reason.length === 0) {
      return openNotification("Success", `The buy Order has been successfully declined.`)
    }

    setLoading(id)
    mutation.mutate(
      {
        method: "UPDATE",
        id: id,
        data: {
          reason,
        },
      },
      {
        onSuccess: (e) => {
          setLoading(false)
          if (e.status === "fail" || e.status === "fail") {
            openNotification("Error", e.message)
            return
          }
          queryClient.setQueryData("custom-request", (old) => old.filter((a) => a._id !== e.data._id))
          openNotification("Success", `The buy Order has been successfully declined.`)
        },
      }
    )
  }

  return (
    <>
      <Button type="primary" loading={isRefetching} onClick={refetch} size="large" className="mb-2">
        Refetch
      </Button>
      {!isLoading && tableData.length === 0 ? (
        <h1>No request found</h1>
      ) : (
        <Table columns={columns} dataSource={tableData} loading={isLoading || isRefetching} />
      )}
    </>
  )
}
export default CustomRequest
