import axios from "axios"
import TryCatch from "../utils/tryCatch"
import { uri } from "../utils/uri"

axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = "X-XSRF-TOKEN"
axios.defaults.withCredentials = true

class API {
  constructor(data, header) {
    this.path = uri
    this.data = data
    this.header = {
      "Content-Type": "application/json",
      apiToken: "YJemEfgL559E9zEhG5<D62Sa?GQO",
      ...header,
    }
  }
  async auth(path) {
    return await TryCatch("POST", `${this.path}/auth${path}`, this.data, this.header)
  }
  async user(method, endPoint) {
    return await TryCatch(method, `${this.path}/${endPoint}`, this.data, this.header)
  }
  async get(endPoint) {
    return await TryCatch("GET", `${this.path}/${endPoint}`, {}, this.header)
  }
  async update(endPoint) {
    return await TryCatch("PATCH", `${this.path}/${endPoint}`, this.data, this.header)
  }
  async delete(endPoint) {
    return await TryCatch("DELETE", `${this.path}/${endPoint}`, this.data, this.header)
  }

  async fetchData(path) {
    const { data } = await axios({
      method: "GET",
      url: `${this.path}/${path}`,
      headers: this.header,
    })
    return data?.data
  }

  async fetchDataAll(path) {
    const { data } = await axios({
      method: "GET",
      url: `${this.path}/${path}`,
      headers: this.header,
    })
    return data
  }

  async postData(file = {}, path, method = "POST") {
    const formData = new FormData()
    const entries = {
      method,
      url: `${this.path}/${path}`,
      headers: this.header,
      data: this.data,
    }

    if (Object.keys(file).length > 0) {
      formData.append("image", file)

      for (const formDataKey in this.data) {
        formData.append(formDataKey, this.data[formDataKey])
      }

      if (this.data.variations) {
        formData.delete("variations")
        formData.append("variations", JSON.stringify(this.data.variations))
      }

      if (this.data.options) {
        formData.delete("options")
        formData.append("options", JSON.stringify(this.data.options))
      }
      if (this.data.option) {
        formData.delete("option")
        formData.append("option", JSON.stringify(this.data.option))
      }

      if (this.data.item) {
        formData.delete("item")
        formData.append("item", JSON.stringify(this.data.item))
      }

      if (this.data.retailers && this.data.retailers.length > 0) {
        formData.delete("retailers")
        formData.append("retailers", JSON.stringify(this.data.retailers))
      }

      entries.headers = {
        ...entries.headers,
        "Content-Type": "multipart/form-data",
      }
      entries.data = formData
    }
    try {
      const { data } = await axios({
        ...entries,
      })
      return data
    } catch (err) {
      return err.response.data
    }
  }

  async deleteData(path) {
    const { data } = await axios({
      method: "DELETE",
      url: `${this.path}/${path}`,
      headers: this.header,
      withCredentials: true,
    })
    return data?.data
  }
}

export default API
