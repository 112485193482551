import React from "react"
import Wrapper from "../../components/Wrapper"
import Product from "../../components/product/pending"

export default function Pending() {
  return (
    <Wrapper>
      <Product status="pending" name="pending-products" />
    </Wrapper>
  )
}
