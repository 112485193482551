import { Alert, Button, Form, Select, Space, InputNumber } from "antd"
import React, { useState } from "react"
import Wrapper from "../../components/Wrapper"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"

export default function UserBalance() {
  const [selectedUser, setSelectedUser] = useState(null)
  const [form] = Form.useForm()

  const { data, isLoading } = UseData("all-users", "user/all-users")

  const mutation = useReactQueryMutation("all-users", "user/balance")
  const handleOnChange = (id) => {
    const user = data.find((d) => d._id === id)
    setSelectedUser(user)

    form.resetFields()
    form.setFieldsValue({
      role: user.role,
      user: user._id,
    })
  }
  const submitHandle = async (formValue) => {
    mutation.mutate(
      {
        id: formValue.user,
        data: {
          balance: Number(formValue.balance),
        },
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return
          openNotification("Success", `User "${selectedUser.email}" role has been updated to ${formValue.balance}.`)
          setSelectedUser((prev) => ({
            ...prev,
            balance: {
              ...formValue.balance,
              available: formValue.balance,
            },
          }))
        },
      }
    )
  }
  return (
    <Wrapper>
      <h4>Update User Balance</h4>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Form.Item label="Select User Role" name="user" rules={[{ required: true, message: "Please select user" }]}>
          <Select
            placeholder="Select a User"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            loading={isLoading}
            onChange={handleOnChange}
          >
            {data.map((user) => (
              <Select.Option value={user._id} key={user._id}>
                {user.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedUser && (
          <Space direction="vertical" size={10}>
            <Alert showIcon message={`Current Available balance is : ${selectedUser.balance.available}`} type="info" />
            <Form.Item name="balance">
              <InputNumber />
            </Form.Item>
            <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
              Update
            </Button>
          </Space>
        )}
      </Form>
    </Wrapper>
  )
}
