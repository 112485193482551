import { useState } from "react"
import ProductTypeForm from "./ProductTypeForm"
import ProductTypeTable from "./ProductTypeTable"

export default function ProductType() {
  const [editId, setEditId] = useState(null)
  const handleEdit = (id) => {
    setEditId(id)
  }
  return (
    <>
      <ProductTypeForm editId={editId} handleEdit={handleEdit} />
      <ProductTypeTable handleEdit={handleEdit} />
    </>
  )
}
