import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Table } from "antd"
import { useState } from "react"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { UseData } from "../../hooks/useReactQuery"
import { openNotification } from "../../utils/notification"
import { getImagePath } from "../../utils/uri"

export default function ProductTypeTable({ handleEdit }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { data, isLoading } = UseData("product-types", "product-type")

  const mutation = useReactQueryMutation("product-types", "product-type")

  const deleteHandel = (id, name) => {
    setConfirmLoading(true)
    mutation.mutate(
      {
        id: id,
        method: "DELETE",
      },
      {
        onSuccess: (e) => {
          openNotification("success", `Product type "${name}" has been deleted.`)
          setVisible(false)
          setConfirmLoading(false)
        },
      }
    )
  }
  const showPopConfirm = (id) => {
    setVisible(id)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div className=" capitalize">{text}</div>,
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      responsive: ["xl"],
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      responsive: ["xl"],
      render: (images) => (
        <>
          {images && (
            <div className="w-12 border border-gray-300 p-0.5 rounded-sm block">
              <img src={getImagePath(images.medium.name)} alt="" />
            </div>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Button size="medium" type="primary" shape="circle" icon={<EditOutlined />} onClick={() => handleEdit(record._id)} />
          <Popconfirm
            title={`Are sure you want to delete this "${record.name}" Product type?`}
            open={record.key === visible}
            onConfirm={() => deleteHandel(record._id, record.name)}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={() => setVisible(false)}
          >
            <Button size="medium" type="primary" danger shape="circle" onClick={() => showPopConfirm(record.key)} icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const tableData = data.map((d) => ({ key: d._id, ...d }))
  if (tableData.length === 0 && !isLoading) return null
  return (
    <div className="pt-5 border-t border-gray-300 mt-8">
      <h1 className="font-bold text-xl pb-3">All Categories</h1>
      <Table columns={columns} dataSource={tableData} loading={isLoading} pagination={{ pageSize: 100 }} />
    </div>
  )
}
