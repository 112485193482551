import { useState, useEffect } from "react"
import { Form, Input, Button, Alert } from "antd"
import { useQueryClient } from "react-query"
import FileUpload from "../FileUpload"
import { openNotification } from "./../../utils/notification"
import { useReactQueryMutation } from "../../hooks/useMutation"

export default function CatForms({ editId, handleEdit }) {
  const [file, setFile] = useState({})
  const [fileSet, setFileSet] = useState(true)
  const [success, setSuccess] = useState(false)
  const [imageName, setImageName] = useState(null)
  const [form] = Form.useForm()

  const queryClient = useQueryClient()

  const mutation = useReactQueryMutation("category", "category", file)

  const setFileHandle = (e) => {
    setFileSet(true)
    setFile(e)
  }

  const submitHandle = async (formValue) => {
    setSuccess(false)

    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") {
            openNotification("Error", e.message)
            return
          }
          if (Object.keys(file).length > 0) {
            setSuccess(true)
          }
          openNotification("Success", `New category "${formValue.name}" has been created.`)
          form.resetFields()
          setFile({})
        },
      }
    )
    setFileSet(true)
  }

  const cancelEditHandle = () => {
    handleEdit(null)
    form.resetFields()
  }

  const updateHandle = (formValue) => {
    mutation.mutate(
      {
        id: editId,
        data: formValue,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return
          openNotification("Success", `Category "${formValue.name}" has been updated.`)
          setSuccess(true)
          handleEdit(null)
          form.resetFields()
          setFile({})
          setImageName(null)
        },
      }
    )
  }

  useEffect(() => {
    if (editId) {
      const [data] = queryClient.getQueryData("category").filter((a) => a._id === editId)
      form.setFieldsValue({
        name: data.name,
        short: data.short,
      })
      setImageName(data.image)
      return
    }
    form.resetFields()
    setImageName()
  }, [editId])

  return (
    <>
      <h1 className="font-bold text-xl">Add Category</h1>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={editId ? updateHandle : submitHandle}>
        <Form.Item label="Category" name="name" rules={[{ required: true, message: "Please input category name!" }]}>
          <Input />
        </Form.Item>

        <FileUpload setFileHandle={setFileHandle} image={imageName} success={success} />

        {!fileSet && (
          <div className="mb-5">
            <Alert message="Please select the image!" type="error" showIcon />
          </div>
        )}

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}
        <Form.Item>
          <div className="flex space-x-4">
            <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
              {editId ? "Update and Upload" : "Crate and Upload"}
            </Button>
            {editId && (
              <Button type="primary" className="button px-10" danger onClick={cancelEditHandle}>
                Cancel Edit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
