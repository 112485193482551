import React from "react"
import Wrapper from "../components/Wrapper"
import Announcement from "../components/announcement"

export default function AnnouncementPage() {
  return (
    <Wrapper>
      <Announcement />
    </Wrapper>
  )
}
