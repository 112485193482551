import React, { useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { Button, Popover, Input, Form } from "antd"
import API from "../../../helper/api"
import { openNotification } from "../../../utils/notification"

export default function ApproveDeclineButton({ disputeItem, reason, onClose, selectedReasons }) {
  const declineReasonRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [declinedLoading, setDeclineReason] = useState(false)
  const queryClient = useQueryClient()

  const handleConfirm = async () => {
    setLoading(true)

    const [res, err] = await new API({
      reasonId: reason._id,
    }).update(reason.status === "approved" ? `dispute/admin/response/approve/${disputeItem._id}` : `dispute/admin/reason/approve/${disputeItem._id}`)
    setLoading(false)

    if (res?.status === "fail" || err) {
      return openNotification("Error", err ? err.message : res.message)
    }
    openNotification("Success", `${reason.status === "approved" ? "Response" : "Reason"} has been successfully approved.`)
    await queryClient.invalidateQueries(["pending-dispute"])
    onClose()
  }

  const handleDeclined = async () => {
    const declinedMessage = declineReasonRef.current.resizableTextArea.textArea.value
    if (declinedMessage === "" || !declinedMessage) {
      return openNotification("Error", "Declined message required.")
    }
    setDeclineReason(true)

    const [res, err] = await new API({
      reasonId: reason._id,
      declinedMessage: declinedMessage,
    }).update(
      reason.status === "approved" ? `dispute/admin/response/declined/${disputeItem._id}` : `dispute/admin/reason/declined/${disputeItem._id}`
    )
    setDeclineReason(false)

    if (res?.status === "fail" || err) {
      return openNotification("Error", err ? err.message : res.message)
    }
    openNotification("Success", `${reason.status === "approved" ? "Response" : "Reason"} has been successfully declined.`)
    await queryClient.invalidateQueries(["pending-dispute"])
    onClose()
  }
  return (
    <>
      <Popover
        content={
          <div className="w-72">
            <Form layout="vertical">
              <Form.Item label="Reason" required tooltip="This is a required field" rules={[{ required: true }]}>
                <Input.TextArea rows={4} ref={declineReasonRef} />
              </Form.Item>
              <Button type="primary" size="large" htmlType="submit" onClick={handleDeclined} loading={declinedLoading}>
                Submit
              </Button>
            </Form>
          </div>
        }
        trigger="click"
        title="Decline Reason"
      >
        <Button type="primary" danger size="large" disabled={loading || declinedLoading} loading={declinedLoading}>
          Decline
        </Button>
      </Popover>
      <Popover
        content={
          <div className="w-72">
            <p>Are you sure you want to approve this.</p>
            <Button type="primary" size="large" onClick={handleConfirm} loading={loading}>
              Yes
            </Button>
          </div>
        }
        trigger="click"
        title="Confirm"
      >
        <Button type="primary" size="large" disabled={loading || declinedLoading}>
          Approve
        </Button>
      </Popover>
    </>
  )
}
