import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Menu, Button } from "antd"
import { ThemeContext } from "../../store/themeContext"
import { LogoutOutlined } from "@ant-design/icons"
import API from "../../helper/api"

const items1 = ["/brand", "/category", "/products"].map((key) => ({
  key,
  label: <Link to={key}>nav {key}</Link>,
}))

const Header = () => {
  const user = useContext(ThemeContext)
  const [loading, setLoading] = useState(false)
  const handleLogout = async () => {
    setLoading(true)
    const [res, err] = await new API().get("user/logout")
    setLoading(false)
    if (err || res.status === "fail") return
    window.location.href = "/"
  }
  return (
    <header className="header fixed left-0 pl-[200px] w-full z-10 top-0 flex bg-[#061629] pr-10 items-center">
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]} items={items1} />
      {user.isLoggedIn && <div className="ml-auto text-white">{user.email}</div>}
      <Button loading={loading} onClick={handleLogout} className="ml-5">
        <LogoutOutlined />
        Logout
      </Button>
    </header>
  )
}

export default React.memo(Header)
