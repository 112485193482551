import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"

import BreadCrumb from "./components/BreadCrumb"
import { Loader } from "./components/Loader"
import Sidebar from "./components/Sidebar"
import Footer from "./components/footer"
import Header from "./components/header"
import API from "./helper/api"
import { AuthRoute, RestrictTo } from "./utils/authRouter"

import { ThemeContext } from "./store/themeContext"

import BaseProductPage from "./pages/baseProduct"
import BrandPage from "./pages/brand"
import CategoryPage from "./pages/category"
import DashboardPage from "./pages/dashboard"
import Login from "./pages/login"
import ModelPage from "./pages/model"
import ActiveProduct from "./pages/product/active"
import AddProduct from "./pages/product/add"
import EditProduct from "./pages/product/edit"
import PendingProduct from "./pages/product/pending"
import RetailerPage from "./pages/retailer"
import UserRole from "./pages/settings/userRole"
import ProductOptions from "./pages/productOption"
import AnnouncementPage from "./pages/announcement"
import UserBalance from "./pages/settings/userBalance"
import ProductTypePage from "./pages/productType"
import addOptionPage from "./pages/baseProduct/addOption"
import DeleteModelPage from "./pages/baseProduct/deleteModel"
import RequestPage from "./pages/product/customRequest"
import { useCookies } from "react-cookie"
import WithdrawalPage from "./pages/withdrawal/withdrawal"
import DepositPage from "./pages/deposit/deposit"
import DisputePending from "./pages/disputes/pending"
import DisputeEscalate from "./pages/disputes/escalate"
import DisputeExpired from "./pages/disputes/expired"
import OfferRequest from "./pages/product/offerRequest"

function App() {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({ isLoggedIn: false })
  const [cookies] = useCookies(["sellb_login"])

  let i = 1
  useEffect(() => {
    if (i > 1) return
    ;(async () => {
      if (!cookies?.sellb_login) {
        setLoading(false)
        return
      }
      const [data, err] = await new API().auth("/authenticate")
      setLoading(false)
      if (err) {
        return
      }
      if (data.data.role !== "admin") {
        await new API().user("GET", "/user/logout")

        return
      }
      setUser({ ...data?.data, isLoggedIn: true })
    })()
    i++
  }, [])
  if (loading) return <Loader size="large" />
  return (
    <ThemeContext.Provider value={user}>
      <Router>
        <Switch>
          <AuthRoute exact path="/" component={Login} />
          <>
            <Sidebar />
            <div className="pl-[200px] pt-10">
              <Header />
              <BreadCrumb />
              <RestrictTo path="/dashboard" component={DashboardPage} />
              <RestrictTo path="/category" component={CategoryPage} />
              <RestrictTo path="/product-type" component={ProductTypePage} />
              <RestrictTo path="/brand" component={BrandPage} />
              <RestrictTo path="/base-product/all" component={BaseProductPage} />
              <RestrictTo path="/base-product/add-option" component={addOptionPage} />
              <RestrictTo path="/base-product/delete-option" component={DeleteModelPage} />
              <RestrictTo path="/product-options" component={ProductOptions} />
              <RestrictTo path="/product/active" component={ActiveProduct} />
              <RestrictTo path="/product/pending" component={PendingProduct} />
              <RestrictTo path="/product/add" component={AddProduct} />
              <RestrictTo path="/product/edit/:id" component={EditProduct} />
              <RestrictTo path="/product/request" component={RequestPage} />
              <RestrictTo path="/product/offer-request" component={OfferRequest} />
              <RestrictTo path="/model" component={ModelPage} />
              <RestrictTo path="/retailer" component={RetailerPage} />
              <RestrictTo path="/setting/user-role" component={UserRole} />
              <RestrictTo path="/setting/user-balance" component={UserBalance} />
              <RestrictTo path="/setting/announcement" component={AnnouncementPage} />
              <RestrictTo path="/setting/withdrawal" component={WithdrawalPage} />
              <RestrictTo path="/setting/deposit" component={DepositPage} />

              <RestrictTo path="/disputes/pending" component={DisputePending} />
              <RestrictTo path="/disputes/escalate" component={DisputeEscalate} />
              <RestrictTo path="/disputes/expired" component={DisputeExpired} />

              <Footer />
            </div>
          </>
        </Switch>
      </Router>
    </ThemeContext.Provider>
  )
}

export default App
