import React from "react"
import Wrapper from "../components/Wrapper"
import ProductType from "../components/productType"
export default function ProductTypePage() {
  return (
    <Wrapper>
      <ProductType />
    </Wrapper>
  )
}
