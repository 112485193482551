import React from "react"

export default function TopMenu() {
  return (
    <div className="bg-blue-600 text-white rounded-lg py-3 px-3 flex justify-between mb-3 text-center">
      <div className="w-52 text-left">ID</div>
      <div className="w-48 text-left">Date</div>
      <div className="w-44 text-left">Reason</div>
      <div className="w-28 text-left">Assignee</div>
      <div className="w-20 text-left">Option</div>
    </div>
  )
}
