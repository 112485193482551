import { Alert, Button, ColorPicker, Form, Input } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useQueryClient } from "react-query"
import { useReactQueryMutation } from "../../hooks/useMutation"
import { openNotification } from "../../utils/notification"

const hexString = (color) => {
  if (!color) return
  return typeof color === "string" ? color : color.toHexString()
}

export default function AnnouncementForm({ editId, handleEdit }) {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const mutation = useReactQueryMutation("announcements", "announcement")

  const [uploadPercentage, setUploadPercentage] = useState([])
  const [editedData, setEditedData] = useState(null)

  const submitHandle = async (formValue) => {
    const bgColor = hexString(formValue.backgroundColor)
    const textColor = hexString(formValue.textColor)

    formValue.backgroundColor = bgColor
    formValue.textColor = textColor

    if (!formValue.title && !formValue.message) {
      return openNotification("Error", `One of the field is required.`)
    }

    formValue.backgroundColor = hexString()

    mutation.mutate(
      {
        method: "CREATE",
        data: formValue,
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return
          openNotification("Success", `New Announcement "${formValue.name}" has been created.`)
          setUploadPercentage([])
          form.resetFields()
        },
      }
    )
  }

  const cancelEditHandle = () => {
    handleEdit(null)
    form.resetFields()
  }

  const updateHandle = (formValue) => {
    const bgColor = hexString(formValue.backgroundColor)
    const textColor = hexString(formValue.textColor)

    formValue.backgroundColor = bgColor
    formValue.textColor = textColor

    mutation.mutate(
      {
        id: editId,
        data: formValue,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail" || e.status === "error") return openNotification("Error", e.message)
          openNotification("Success", `Announcement "${formValue.name}" has been updated.`)
          handleEdit(null)
          setUploadPercentage([])
          form.resetFields()
        },
      }
    )
  }

  useEffect(() => {
    if (editId) {
      const [data] = queryClient.getQueryData("announcements").filter((a) => a._id === editId)

      form.setFieldsValue({
        title: data.title,
        message: data.message,
        backgroundColor: data.backgroundColor,
      })

      setEditedData(data)
      return
    }
    form.resetFields()
    setEditedData(null)
  }, [editId])

  return (
    <>
      <h1 className="font-bold text-xl">Add Announcement</h1>

      <Form layout="vertical" name="basic" size="large" form={form} onFinish={editId ? updateHandle : submitHandle}>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>

        <Form.Item name="message" label="Message">
          <Input.TextArea rows={10} />
        </Form.Item>

        <Form.Item name="backgroundColor" label="Background color" initialValue="#fff">
          <ColorPicker showText format="hex" />
        </Form.Item>

        <Form.Item name="textColor" label="Text color" initialValue="#000">
          <ColorPicker showText format="hex" />
        </Form.Item>

        {mutation.error && !mutation.isLoading ? (
          <div className="mb-5">
            <Alert message="Error" description={mutation.error} type="error" showIcon />
          </div>
        ) : null}
        <Form.Item>
          <div className="flex space-x-4">
            {!uploadPercentage.find((u) => u === 0) && (
              <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
                {editId ? "Update" : "Crate"}
              </Button>
            )}
            {editId && (
              <Button type="primary" className="button px-10" danger onClick={cancelEditHandle}>
                Cancel Edit
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </>
  )
}
