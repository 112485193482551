import React, { useEffect, useState } from "react"
import { DeleteOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Table, Tooltip, Tag, Spin } from "antd"
import { openNotification } from "../../../utils/notification"
import { UseData } from "../../../hooks/useReactQuery"
import { getImagePath } from "../../../utils/uri"
import { useQueryClient } from "react-query"
import API from "../../../helper/api"

export default function DeleteTable({ selectedOption }) {
  const [visible, setVisible] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const queryClient = useQueryClient()

  const { data, isLoading, isError, refetch, isRefetching } = UseData(
    `base-products-models-${selectedOption.baseProduct.slug}`,
    `base-product/option/${selectedOption.baseProduct._id}`
  )

  useEffect(() => {
    refetch()
  }, [])

  if (isError) return <h1>Error</h1>

  const deleteHandel = async (optionId) => {
    setConfirmLoading(optionId)

    const [res, error] = await new API().delete(`base-product/option/${selectedOption.baseProduct._id}/${optionId}`)

    setVisible(false)
    setConfirmLoading(false)

    openNotification("success", `Model has delete from "${selectedOption.baseProduct.title}"`)

    queryClient.setQueryData([`base-products-models-${selectedOption.baseProduct.slug}`], [...res.data])
  }

  const showPopConfirm = (id) => {
    setVisible(id)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: "Variations",
      dataIndex: "variations",
      key: "variations",
      render: (variations) => {
        return variations.map((k, i) => {
          return (
            <div className="mb-2" key={`variations-item-${i}`}>
              <strong>{k.name}: </strong>
              {k.items.map((v) => (
                <Tag key={v.name} color="cyan">
                  {v.name}
                </Tag>
              ))}
            </div>
          )
        })
      },
    },
    {
      title: "Image",
      dataIndex: "images",
      key: "images",
      responsive: ["xl"],
      render: (text) => (
        <>
          {text && (
            <div className="w-12 border border-gray-300 p-0.5 rounded-sm block">
              <img src={getImagePath(text.thumbnail.name)} alt="" />
            </div>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex space-x-2">
          <Popconfirm
            title={`Are sure you want to delete this "${record.name}" brand?`}
            open={record.key === visible}
            onConfirm={() => deleteHandel(record._id)}
            okButtonProps={{ loading: confirmLoading === record._id }}
            onCancel={() => setVisible(false)}
          >
            <Button
              loading={confirmLoading === record._id}
              size="medium"
              type="primary"
              danger
              shape="circle"
              onClick={() => showPopConfirm(record.key)}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </div>
      ),
    },
  ]

  if (isLoading) return <Spin />

  if (data.length === 0) return <h1>No data found</h1>

  const tableData = data.map((d) => ({ key: d.name, ...d }))

  return (
    <div className="mt-5">
      <h1>Delete model from {selectedOption.baseProduct.name}</h1>
      <Button type="primary" onClick={refetch} loading={isRefetching}>
        Refetch the data
      </Button>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoading || isRefetching}
        pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ["10", "20", "30"] }}
      />
    </div>
  )
}
