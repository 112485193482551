import React, { useState } from "react"
import { Form, Button, Select } from "antd"

import { useReactQueryMutation } from "../hooks/useMutation"
import { UseData } from "../hooks/useReactQuery"
import { openNotification } from "../utils/notification"

export default function ApproveBetaUser() {
  const [form] = Form.useForm()
  const { data, isLoading } = UseData("all-private-beta-users", "user/all-users?privateBeta=pending")
  const [selectedUser, setSelectedUser] = useState(null)

  const mutation = useReactQueryMutation("all-private-beta-users", "user/approve-beta")

  const handleOnChange = (id) => {
    const user = data.find((d) => d._id === id)
    setSelectedUser(user)
  }
  const submitHandle = async (formValue) => {
    mutation.mutate(
      {
        id: formValue.user,
        method: "UPDATE",
      },
      {
        onSuccess: (e) => {
          if (e.status === "fail") return openNotification("Error", e.message)
          openNotification("Success", `User "${selectedUser.email}" role has been approved.`)

          form.resetFields()
        },
        onError: (e) => {
          openNotification("Error", e.message)
        },
      }
    )
  }
  return (
    <div className="mt-10">
      <h4>Approve beta users</h4>
      <Form layout="vertical" name="basic" size="large" form={form} onFinish={submitHandle}>
        <Form.Item label="Select User" name="user" rules={[{ required: true, message: "Please select user" }]}>
          <Select
            placeholder="Select a User"
            allowClear
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            loading={isLoading}
            onChange={handleOnChange}
          >
            {data.map((user) => (
              <Select.Option value={user._id} key={user._id}>
                {user.email} - {user.role}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" className="button button--green px-10" htmlType="submit" loading={mutation.isLoading}>
          Approve
        </Button>
      </Form>
    </div>
  )
}
