import React from "react"
import Wrapper from "../components/Wrapper"
import Category from "../components/category"

export default function CategoryPage() {
  return (
    <Wrapper>
      <Category />
    </Wrapper>
  )
}
