import { Button, Drawer, Space } from "antd"

import PendingRequestForm from "./PendingRequestForm"

const DrawerSection = ({ open, setOpen, selectedProduct, setSelectedProduct }) => {
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      title="Approve pending product."
      placement="top"
      onClose={onClose}
      open={open}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      size="large"
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
    >
      <div className="flex max-w-4xl mx-auto">
        <PendingRequestForm setOpen={setOpen} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
      </div>
    </Drawer>
  )
}

export default DrawerSection
