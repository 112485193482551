import React from "react"
import { Col, Form, Row, Select } from "antd"
import { UseData } from "../../../hooks/useReactQuery"
import FileUpload from "../../FileUpload"
import RefetchButton from "../../RefetchButton"

export default function EditVariation({ selectedOption, setSelectedOption, success, imageName, setFileHandle, form, setImageName }) {
  const { data, isLoading, isError, refetch, isRefetching } = UseData(
    `variations-${selectedOption.baseProduct._id}`,
    `base-product/variation/${selectedOption.baseProduct._id}`
  )

  if (isError) return "Error"

  const handleOnChange = (name, value) => {
    const parsedValue = value ? JSON.parse(value) : ""
    setSelectedOption((prev) => ({ ...prev, [name]: parsedValue }))

    if (name === "name") {
      form.setFieldsValue({
        value: "",
      })
      return
    }
    if (name === "value") {
      setImageName(parsedValue.image)
    }
  }

  return (
    <>
      <Row gutter={20}>
        <Col span={12}>
          <div className="relative pr-10">
            <Form.Item
              name="name"
              label="Variation name"
              rules={[
                {
                  required: true,
                  message: "Please select variation name",
                },
              ]}
            >
              <Select
                placeholder="Select a name"
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                loading={isLoading}
                onChange={(v) => handleOnChange("name", v)}
              >
                {data?.map((d) => (
                  <Select.Option value={JSON.stringify(d)} key={d.name} className="capitalize">
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <RefetchButton name="Variation" loading={isRefetching || isLoading} refetch={refetch} />
          </div>
        </Col>
        {selectedOption?.name && (
          <Col span={12}>
            <div className="relative pr-10">
              <Form.Item
                name="value"
                label="Variation Value"
                rules={[
                  {
                    required: true,
                    message: "Please select variation value",
                  },
                ]}
              >
                <Select
                  placeholder="Select a value"
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  loading={isLoading}
                  onChange={(v) => handleOnChange("value", v)}
                >
                  {selectedOption?.name?.items?.map((d) => (
                    <Select.Option value={JSON.stringify(d)} key={d.name} className="capitalize">
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <RefetchButton name="Option name" loading={isRefetching || isLoading} refetch={refetch} />
            </div>
          </Col>
        )}
        <Col span={24}>
          <FileUpload setFileHandle={setFileHandle} image={imageName} success={success} path="variations" />
        </Col>
      </Row>
    </>
  )
}
