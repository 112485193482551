import { Button, Drawer, Space, Tabs, Tag } from "antd"

import CustomRequestForm from "./CustomRequestForm"
import SelectProduct from "./SelectProduct"

const DrawerCustom = ({ open, setOpen, setSelectedProduct, selectedProduct }) => {
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      title="Create product and approve buy order."
      placement="top"
      onClose={onClose}
      open={open}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      size="large"
      extra={
        <Space>
          <Button onClick={onClose}>Close</Button>
        </Space>
      }
    >
      <div className="flex max-w-6xl mx-auto">
        <Tabs
          className="w-2/3"
          type="card"
          animated={true}
          items={[
            {
              key: "tab-1",
              label: "Create Product and Approve",
              children: <CustomRequestForm setOpen={setOpen} setSelectedProduct={setSelectedProduct} selectedProduct={selectedProduct} />,
            },
            {
              key: "tab-2",
              label: "Add Product to Sell Offer",
              children: <SelectProduct setOpen={setOpen} selectedProduct={selectedProduct} />,
            },
          ]}
        />
        {selectedProduct && (
          <div className="w-1/3 pl-14 my-auto">
            {selectedProduct.customDetails && (
              <div className="space-y-4">
                <h3>Custom Details</h3>
                <div>
                  name: <Tag color="blue">{selectedProduct?.customDetails?.title}</Tag>
                </div>
                <div>
                  Category: <Tag color="blue">{selectedProduct?.customDetails?.category}</Tag>
                </div>
                <div>
                  Product Type: <Tag color="blue">{selectedProduct?.customDetails?.productType}</Tag>
                </div>
                <div>
                  Brand: <Tag color="blue">{selectedProduct?.customDetails?.brand}</Tag>
                </div>
                <div>
                  Base Product: <Tag color="blue">{selectedProduct?.customDetails?.baseProduct?.name}</Tag>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default DrawerCustom
