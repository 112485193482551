import React from "react"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Col, Form, Row, Select } from "antd"
import { UseData } from "../../../hooks/useReactQuery"
import FileUpload from "../../FileUpload"
import RefetchButton from "../../RefetchButton"

const VariationItem = ({ field, remove, selectedVariation }) => {
  const { data, isLoading, isError, refetch, isRefetching } = UseData("options", "product-option/all")

  if (isError) return null

  const variations = data.find((d) => d.name === selectedVariation.name)?.variations

  return (
    <div className="w-1/3 px-4 flex flex-wrap options-outer relative pr-[62px]">
      <Form.Item
        {...field}
        label="value"
        name={[field.name, "name"]}
        key={`value-${field.key}`}
        rules={[
          {
            required: true,
            message: "Missing value name",
          },
        ]}
      >
        <Select
          placeholder="Select value"
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          loading={isLoading || isRefetching}
        >
          {variations?.map((v) => (
            <Select.Option value={JSON.stringify(v)} key={v.name} className="capitalize">
              {v.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <RefetchButton name="Value" loading={isLoading || isRefetching} refetch={refetch} className="top-8 right-7" />
      <MinusCircleOutlined className="absolute right-0 top-9 text-xl text-red-500" onClick={() => remove(field.name)} />
    </div>
  )
}

export default function AddOption({ selectedOption, setSelectedOption, success, imageName, setFileHandle, form }) {
  const { data, isLoading, isError, refetch, isRefetching } = UseData("options", "product-option/all")

  if (isError) return "Error"

  const handleOnChange = (name, value) => {
    setSelectedOption((prev) => ({ ...prev, [name]: JSON.parse(value) }))

    if (name === "optionName") {
      form.setFieldsValue({
        variations: [],
        optionValue: "",
        variationName: "",
      })
      return
    }
    if (name === "optionValue") {
      form.setFieldsValue({
        variations: [],
        variationName: "",
      })
      return
    }

    form.setFieldsValue({
      variations: [],
    })
  }

  return (
    <>
      <Row gutter={20}>
        <Col span={12}>
          <div className="relative pr-10">
            <Form.Item
              name="optionName"
              label="Option Key Name"
              rules={[
                {
                  required: true,
                  message: "Please select Key name",
                },
              ]}
            >
              <Select
                placeholder="Select a key name"
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                loading={isLoading}
                onChange={(v) => handleOnChange("optionName", v)}
              >
                {data?.map((d) => (
                  <Select.Option value={JSON.stringify(d)} key={d.name} className="capitalize">
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <RefetchButton name="Model" loading={isRefetching || isLoading} refetch={refetch} />
          </div>
        </Col>
        {selectedOption?.optionName && (
          <Col span={12}>
            <div className="relative pr-10">
              <Form.Item
                name="optionValue"
                label="Option key Value"
                rules={[
                  {
                    required: true,
                    message: "Please select option key value",
                  },
                ]}
              >
                <Select
                  placeholder="Select a option key value"
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  loading={isLoading}
                  onChange={(v) => handleOnChange("optionValue", v)}
                >
                  {selectedOption?.optionName?.variations?.map((d) => (
                    <Select.Option value={JSON.stringify(d)} key={d.name} className="capitalize">
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <RefetchButton name="Option name" loading={isRefetching || isLoading} refetch={refetch} />
            </div>
          </Col>
        )}
        <Col span={24}>
          <FileUpload setFileHandle={setFileHandle} image={imageName} success={success} />
        </Col>
        <Col span={24}>
          {selectedOption?.optionValue && (
            <div className="relative pr-10">
              <Form.Item name="variationName" label="Select Variation option">
                <Select
                  placeholder="Select a Variation option"
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  loading={isLoading}
                  onChange={(v) => handleOnChange("variationName", v)}
                >
                  {data?.map((d) => (
                    <Select.Option value={JSON.stringify(d)} key={d.name} className="capitalize">
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <RefetchButton name="Options" loading={isRefetching || isLoading} refetch={refetch} />
            </div>
          )}
        </Col>
      </Row>

      {selectedOption?.optionName && selectedOption?.optionValue ? (
        <Form.List name="variations">
          {(fields, { add, remove }) => (
            <>
              <div className="flex flex-wrap relative -mx-4">
                {fields.map((field, i) => (
                  <VariationItem
                    options={data}
                    selectedVariation={selectedOption["variationName"]}
                    key={`variation-${i}`}
                    field={field}
                    remove={remove}
                  />
                ))}
              </div>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Variation
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      ) : null}
    </>
  )
}
